import React, { useEffect } from 'react'
import HeroSection from '../home/HeroSection'
import CtaSection from '../home/CtaSection'
import ContactSection from '../home/ContactSection'
import { useDispatch, useSelector } from 'react-redux'
import { getGeneralSetting } from '../../statemanagement/common/action'

const ContactUs = () => {
    let dispatch = useDispatch()

    const { commonLoader, generalSettingList } = useSelector((state) => state.common)
    useEffect(() => {
        dispatch(getGeneralSetting())
    }, [])

    return (
        <>
            <HeroSection />
            <div className='my-10'>
                <CtaSection isText data={generalSettingList} />
            </div>
            <ContactSection />

        </>
    )
}

export default ContactUs