import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css'; // Make sure to import Swiper styles

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules'
import { Pagination } from 'swiper/modules';
import { GoArrowLeft, GoArrowRight } from 'react-icons/go';
import { tickets } from '../../helper/constant';
import { Barcode_icon, Bus_img, Ellipse1_img, Line_img, Star_img, Ticket_icon } from '../../helper/imageConstant';

const TicketCard = ({ type, price, features, heading, subheading, save }) => {

    return (
        <div className={`w-full rounded-2xl shadow-xl`}>
            <div className={`p-2 text-[#212121] text-center font-bold rounded-t-2xl bg-gradiant-yellow2`}>{type.toUpperCase()}</div>
            <div className='flex justify-end'>
                <aside className="text-end text-white px-4 text-sm bookmarkRibbon">Save {save}%</aside>
            </div>
            <div className='relative p-5 space-y-3 text-[#212121]'>
                <span className='absolute top-2 left-5'>
                    <img src={Star_img} alt='Ellipse_img' className='w-full' />
                </span>
                <h1 className='font-normal text-sm'>
                    {heading}
                    <p> {subheading}</p>
                </h1>
                <span className='absolute top-[35%] left-5'>
                    <img src={Star_img} alt='Ellipse_img' className='w-full' />
                </span>
                <div>
                    <h3 className='text-xs'>Price</h3>
                    <p className="text-center font-bold text-2xl my-1">${price}</p>
                </div>
                <span className='absolute top-[35%] right-5'>
                    <img src={Star_img} alt='Ellipse_img' className='w-full' />
                </span>
                <div>
                    <p className='text-start font-semibold'>Features</p>
                    <div className="w-full text-center mb-2 text-[15px]">
                        {features.map((feature, index) => (
                            <div key={index} className='ml-4 flex items-center gap-2' >
                                <img src={Ticket_icon} alt='Ticket_icon' className='w-6' />{feature}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='relative flex items-center justify-between gap-2' >
                <span className='flex items-center left-0 top-0 bottom-0'>
                    <img src={Ellipse1_img} alt='Ellipse_img' className='w-full' />
                </span>
                <div>
                    <img src={Line_img} alt='Line_img' className='mx-auto' />
                </div>
                <span className='flex items-center left-0 top-0 bottom-0'>
                    <img src={Ellipse1_img} alt='Ellipse_img' className='rotate-180' />
                </span>
            </div>
            <div className='p-5 space-y-3 text-[#212121]'>
                <div className="text-center mb-2">
                    <img src={Barcode_icon} alt="barcode" className='mx-auto' />
                    <p className='text-xs'>A345 - 343 -23232 - 34343 </p>
                </div>
                <div className="text-center mt-2">
                    <img src={Bus_img} alt="bus" className='w-full mx-auto' />
                </div>
            </div>
        </div>
    );
};

const PackageSection = () => {
    return (
        <section className='max-w-7xl mx-auto my-5 lg:my-10'>
            <div className='text-center relative'>
                <h2 className='text-xl lg:text-[32px] text-[#212121] font-semibold mb-2 lg:mb-4'>Exclusive Partner Offers</h2>
                <p className='lg:w-[65%] mx-auto lg:title-line lg:pb-3'>Explore our expertly designed travel packages featuring seamless NYC shuttle services, reliable airport transfers, and unforgettable Hoboken sightseeing tours—crafted to provide you with the ultimate travel experience.</p>
            </div>
            <div className='w-full'>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={0}
                    loop={true}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                        renderBullet: (index, className) => {
                            // Render only the first 3 bullets
                            if (index < 3) {
                                return `<span class="${className}"></span>`;
                            }
                            return ''; // Hide bullets beyond the 3rd
                        },
                    }}
                    onSlideChange={(swiper) => {
                        // Update bullets dynamically to reflect the active slide
                        const bullets = document.querySelectorAll('.swiper-pagination-bullet');
                        bullets.forEach((bullet, index) => {
                            bullet.classList.remove('swiper-pagination-bullet-active');
                            if (index === swiper.realIndex % 3) {
                                bullet.classList.add('swiper-pagination-bullet-active');
                            }
                        });
                    }}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 0,
                            centeredSlides: true,
                            loop: true
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 0,
                            centeredSlides: true,
                            loop: true
                        },
                        1024: {
                            slidesPerView: 3,
                            spaceBetween: 0,
                            centeredSlides: true,
                            loop: true
                        },
                    }}
                    centeredSlides={true} // Center the active slide
                    navigation={{
                        nextEl: '.swiper-button-next-custom',
                        prevEl: '.swiper-button-prev-custom',
                    }}
                    modules={[Navigation, Pagination]}
                    className="mySwiper w-full pricing_slider !pt-14 lg:!pt-24 !pb-28 lg:!pb-32 relative"
                >
                    {/* Custom Navigation Buttons */}
                    <div className='absolute top-0 right-5 hidden lg:flex justify-center items-center gap-2 mt-5 mb-4'>
                        <div className="swiper-button-prev-custom bg-transparent text-xl text-[#000000] border rounded-full p-3 transition-all ease-in-out duration-75 hover:scale-110"><GoArrowLeft /></div>
                        <div className="swiper-button-next-custom bg-[#FB8500] shadow-md shadow-[#FB8500] text-xl text-[#000000] border border-transparent rounded-full p-3 transition-all ease-in-out duration-75 hover:scale-110"><GoArrowRight /></div>
                    </div>
                    {tickets?.map((ticket, index) => (
                        <SwiperSlide key={index} className='px-5'>
                            <TicketCard {...ticket} />
                        </SwiperSlide>
                    ))}
                    <div className='absolute bottom-2 right-0 left-0 flex lg:hidden justify-center items-center gap-5 mt-20'>
                        <div className="swiper-button-prev-custom bg-transparent text-xl text-[#000000] border rounded-full p-3 transition-all ease-in-out duration-75 hover:scale-110"><GoArrowLeft /></div>
                        <div className="swiper-button-next-custom bg-[#FB8500] shadow-md shadow-[#FB8500] text-xl text-[#000000] border border-transparent rounded-full p-3 transition-all ease-in-out duration-75 hover:scale-110"><GoArrowRight /></div>
                    </div>
                </Swiper>
            </div>
        </section>

    )
}

export default PackageSection