import React, { useState } from 'react'
import { MdClose } from 'react-icons/md';

const InquiryDetailsPopup = ({ isOpen, onClose }) => {

    const [form, setForm] = useState({
        name: '',
        gender: '',
        age: '',
        contact: '',
        email: '',
    });


    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your form submission logic here
        console.log(form);
    };

    if (!isOpen) return null;
    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                <div className="relative w-[640px] h-[600px] overflow-y-scroll bg-popup-bg bg-center bg-no-repeat p-6 rounded-lg shadow-lg">
                    <h2 className="text-lg font-bold mb-4">Inquiry Details</h2>
                    <div className='absolute top-5 right-5 cursor-pointer'>
                        <MdClose size={24} onClick={onClose} />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">Name</label>
                            <input
                                type="text"
                                name="name"
                                value={form.name}
                                onChange={handleChange}
                                className="mt-1 p-2 border-transparent bg-[#ECECEC] placeholder:text-[#212121] w-full rounded-md"
                                placeholder="Enter name"
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={form.email}
                                onChange={handleChange}
                                className="mt-1 p-2 border-transparent bg-[#ECECEC] placeholder:text-[#212121] w-full rounded-md"
                                placeholder="Enter email"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">Mobile </label>
                            <input
                                type="tel"
                                name="contact"
                                value={form.contact}
                                onChange={handleChange}
                                className="mt-1 p-2 border-transparent bg-[#ECECEC] placeholder:text-[#212121] w-full rounded-md"
                                placeholder="Enter Mobile"
                                required
                            />
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">From which location would you like to be picked up?</label>
                            <select className="mt-1 p-2 border-transparent placeholder:text-[#212121] bg-[#ECECEC] w-full rounded-md">
                                <option defaultValue={"choose location"}>Choose location</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">Where would you like to be dropped off?</label>
                            <select className="mt-1 p-2 border-transparent text-[#212121] bg-[#ECECEC] w-full rounded-md">
                                <option defaultValue={"choose location"}>Choose location</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">What time would you like to depart?</label>
                            <select className="mt-1 p-2 border-transparent text-[#212121] bg-[#ECECEC] w-full rounded-md">
                                <option defaultValue={"choose location"}>Choose location</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">How many adults are in your group?</label>
                            <select className="mt-1 p-2 border-transparent text-[#212121] bg-[#ECECEC] w-full rounded-md">
                                <option defaultValue={"choose Numbers"}>Choose Numbers</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">How many childs are in your group?</label>
                            <select className="mt-1 p-2 border-transparent text-[#212121] bg-[#ECECEC] w-full rounded-md">
                                <option defaultValue={"choose Numbers"}>Choose Numbers</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">How many bags does your group have?</label>
                            <select className="mt-1 p-2 border-transparent text-[#212121] bg-[#ECECEC] w-full rounded-md">
                                <option defaultValue={"choose Numbers"}>Choose Numbers</option>
                            </select>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">What type of car would you prefer?</label>
                            <select className="mt-1 p-2 border-transparent text-[#212121] bg-[#ECECEC] w-full rounded-md">
                                <option defaultValue={"choose car"}>Choose Car</option>
                            </select>
                        </div>

                        <div className="flex justify-center items-center">
                            <button
                                onClick={onClose}
                                type="submit"
                                className="w-2/4 bg-gradiant-yellow2 text-[#212121] font-semibold uppercase rounded-md py-2 px-10"
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default InquiryDetailsPopup