import React, { useEffect, useState } from 'react'
import HeroSection from '../home/HeroSection'
import { Bookingleftside, Cloudsandcity, Our_Partner, TAB1, TAB2, TAB3, TAB4 } from '../../helper/imageConstant'
import FaqSection from '../home/FaqSection'
import { bookingData } from '../../helper/constant'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules'
import 'swiper/swiper-bundle.css'; // Make sure to import Swiper styles

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { GoArrowLeft, GoArrowRight } from 'react-icons/go'
import Bookingcomponent from '../../component/Bookingcomponent'
import InquiryDetailsPopup from '../../component/InquiryDetailsPopup'
import { CiSearch } from 'react-icons/ci'
import { BsClock } from 'react-icons/bs'
import { SlLocationPin } from 'react-icons/sl'
import { useDispatch, useSelector } from 'react-redux'
import { fetchFaqData, fetchProducts } from '../../statemanagement/common/action'
import { useLocation } from 'react-router-dom'

const DatePicker = ({ date, onDateChange }) => {
    const dates = ["13th April 2024", "13th April 2024", "13th April 2024", "13th April 2024", "13th April 2024", "13th April 2024", "13th April 2024"];
    const initialIndex = dates.indexOf(date);
    const [activeIndex, setActiveIndex] = useState(initialIndex);
    return <>
        <div className='max-w-6xl mx-auto relative my-10 lg:my-0'>
            <Swiper
                spaceBetween={10}
                slidesPerView={1}
                loop={true}
                centeredSlides={true}
                onSlideChange={(swiper) => {
                    setActiveIndex(swiper.realIndex);
                    onDateChange(dates[swiper.activeIndex])
                }}
                initialSlide={initialIndex}
                modules={[Navigation]}
                navigation={{
                    nextEl: '.swiper-button-next-custom',
                    prevEl: '.swiper-button-prev-custom',
                }}
                breakpoints={{
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 20,
                        loop: true,
                        centeredSlides: true

                    },
                    768: {
                        slidesPerView: 4,
                        spaceBetween: 20,
                        loop: true,
                        centeredSlides: true
                    },
                    992: {
                        slidesPerView: 5,
                        spaceBetween: 40, loop: true,
                        centeredSlides: true
                    },
                }}
                className="mySwiper date_slider w-2/4 "
            >
                {dates.map((date, index) => (
                    <SwiperSlide key={index} className=''>
                        <div className=''>
                            <div className={`mx-20 sm:mx-10 md:mx-0 text-center text-sm px-4 lg:px-7 py-3 border rounded-full cursor-pointer ${activeIndex === index ? 'bg-gradiant-yellow4' : 'bg-transparent'}`}>

                                {date}
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
                {/* Custom Navigation Buttons */}
                {/* <div className=' mt-10'> */}
                <div className="swiper-button-prev-custom absolute top-0 left-0 z-50 bg-gradiant-yellow4 text-xl text-[#000000] border border-transparent rounded-full p-3 transition-all ease-in-out duration-75"><GoArrowLeft /></div>
                <div className="swiper-button-next-custom absolute top-0 right-0 z-50 bg-gradiant-yellow4 text-xl text-[#000000] border border-transparent rounded-full p-3 transition-all ease-in-out duration-75"><GoArrowRight /></div>
                {/* </div> */}
            </Swiper>
        </div >
    </>
};
const TripDetails = () => {
    const trips = [
        { time: '10:30', ampm: "pm", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '11:30', ampm: "pm", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '12:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '01:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '02:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '03:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '10:30', ampm: "pm", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '11:30', ampm: "pm", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '12:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '01:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '02:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
        { time: '03:30', ampm: "am", tilltime: "till 02:45 am", route: 'New York to Texas' },
    ];
    return <>
        <Swiper
            spaceBetween={20}
            slidesPerView={2}
            // centeredSlides={true}
            loop={true}
            breakpoints={{
                640: {
                    slidesPerView: 2,
                    spaceBetween: 20,
                    loop: true,
                    centeredSlides: true

                },
                768: {
                    slidesPerView: 4,
                    spaceBetween: 20, loop: true,
                    centeredSlides: true
                },
                992: {
                    slidesPerView: 5,
                    spaceBetween: 40, loop: true,
                    centeredSlides: true
                },
                1200: {
                    slidesPerView: 6,
                    spaceBetween: 50, loop: true,
                    centeredSlides: true
                },
            }}
            className="mySwiper"

        >
            {trips.map((trip, index) => (
                <SwiperSlide key={index}>
                    <div className="p-4 py-4 border border-[#212121] rounded-[20px] space-y-3">
                        <div>
                            <p className='text-2xl lg:text-[32px] font-semibold'>{trip.time} <spn className={"text-[20px] font-normal"}>{trip?.ampm}</spn></p>
                            <p className='text-xs lg:text-[13px] text-end'>
                                {trip?.tilltime}
                            </p>
                        </div>
                        <p className='text-xs lg:text-sm font-semibold'>{trip.route}</p>
                        <button className="w-full mt-2 text-xs px-4 py-2 border border-[#212121] font-semibold rounded-full"> More Details</button>
                    </div>
                </SwiperSlide>
            ))}
        </Swiper>
    </>
};
const Tab = ({ title, onClick, active }) => {
    return (
        <button
            onClick={onClick}
            className={`py-2 px-4 font-semibold border-b-2 ${active ? 'border-blue-500 text-blue-500' : 'border-transparent text-gray-500'
                } hover:text-blue-500 focus:outline-none`}
        >
            {title}
        </button>
    );
};
const SelectService = ({ productId, onProductSelect }) => {
    const { productList } = useSelector((state) => state.common)
    const handleSelectChange = (event) => {
        const selectedProduct = productList.find((product) => product?.id === event.target.value);
        onProductSelect(selectedProduct); // Pass the selected product to parent
    };

    return (
        <div className="my-5 lg:py-10 relative">
            {/* <div className="absolute top-10 left-10">
                <svg className="h-6 w-6 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M3 12h18M3 6h18M3 18h18" />
                </svg>
            </div> */}
            <div className="">
                <h2 className="text-lg font-semibold mb-2">Select Product </h2>
                <div className="relative inline-block w-full">
                    <select onChange={handleSelectChange}
                        className="block appearance-none w-full bg-white border border-[#FFB703] hover:border-[#212121] px-4 py-2.5 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                        <option value="">Choose service</option>
                        {productList && productList?.map((product) => (
                            <option key={product?.id} value={product?.id}>{product?.name}</option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M7 10l5 5 5-5H7z" /></svg>
                    </div>
                </div>
            </div>
            {/* <div className="absolute bottom-10 right-10">
                <svg className="h-6 w-6 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M3 12h18M3 6h18M3 18h18" />
                </svg>
            </div>
            <div className="absolute bottom-10 left-10">
                <svg className="h-6 w-6 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M3 12h18M3 6h18M3 18h18" />
                </svg>
            </div>
            <div className="absolute top-10 right-10">
                <svg className="h-6 w-6 text-gray-400" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <path d="M3 12h18M3 6h18M3 18h18" />
                </svg>
            </div> */}
        </div>
    );
};
const ServiceTabs = ({ onTabChange, trip, productId }) => {
    const tabs = [
        { id: 1, label1: 'Daily Rides to the Big ', label2: "Apple: Your Ticket to NYC", image: TAB1 },
        { id: 2, label1: 'AeroRide: Your Quick', label2: "Airport Taxi", image: TAB2 },
        { id: 3, label1: 'DealDazzle', label2: "Discounted Attractions", image: TAB3 },
        { id: 4, label1: 'WanderWise ', label2: "Group Tours & Travel", image: TAB4 },
    ];

    const [activeTab, setActiveTab] = useState(0);
    const [selectedDate, setSelectedDate] = useState('2024-04-13');
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null); // New state for selected product

    const togglePopup = () => {
        setPopupOpen(!isPopupOpen);
    };
    const handleDateChange = (date) => {
        setSelectedDate(date);
    };

    return (
        <>
            <div>
                <div className="max-w-7xl mx-auto w-full grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-5 mt-5 py-4">
                    {tabs.map((tab, index) => (
                        <div
                            key={tab.id}
                            className={`p-2 lg:p-4 border border-[#FFB703] cursor-pointer rounded-lg ${activeTab === index ? 'tab_bg_color' : ''}`}
                            onClick={() => setActiveTab(index)}
                        >
                            <div className='mb-2 px-10 lg:px-0'>
                                <img src={tab?.image} alt='img' className='object-cover mx-auto' />
                            </div>
                            <h2 className='text-center text-[#212121] font-semibold text-xs lg:text-base'>
                                {tab.label1}
                            </h2>
                            <h2 className='text-center text-[#212121] font-semibold text-xs lg:text-base'>
                                {tab.label2}
                            </h2>
                        </div>
                    ))}
                </div>
                <div>
                    {activeTab === 0 &&
                        <div>
                            <div className='max-w-xl mx-auto'>
                                <SelectService productId={productId} onProductSelect={(product) => setSelectedProduct(product)} />
                            </div>
                            {selectedProduct && <div className='max-w-7xl mx-auto'>
                                <div className='lg:!relative w-full lg:flex justify-center '>
                                    <img src={Cloudsandcity} alt='Cloudsandcity' className='w-full mx-auto' />
                                    <div className='hidden lg:block w-full absolute top-52 z-30'>
                                        <Bookingcomponent trip={false} />
                                    </div>
                                    <div class="my-2 lg:hidden rounded-[14px] lg:rounded-lg  lg:shadow-xl ">
                                        <form className="space-y-4 lg:space-y-4">
                                            <div>
                                                <label className="block text-sm font-semibold text-[#212121]">Depart</label>
                                                <div className='relative flex items-center '>
                                                    <input
                                                        type="date"
                                                        className="mt-1 p-2 pl-8 border-transparent bg-[#ECECEC] placeholder:text-xs placeholder:text-[#212121] w-full rounded-md"
                                                    />
                                                    <BsClock className='absolute mt-0.5 bg-clip-text top-[50%] left-2 translate-y-[-50%]  text-[#FB8500]' />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-sm font-semibold text-[#212121]">Return</label>
                                                <div className='relative flex items-center '>

                                                    <input
                                                        type="date"
                                                        className="mt-1 pl-8 p-2 border-transparent bg-[#ECECEC] placeholder:text-[#212121] w-full rounded-md"
                                                    />
                                                    <BsClock className='absolute mt-0.5 bg-clip-text top-[50%] left-2 translate-y-[-50%]  text-[#FB8500]' />
                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-sm font-semibold text-[#212121]">From</label>
                                                <div className='relative flex items-center '>
                                                    <input
                                                        type="text"
                                                        placeholder="Location"
                                                        className="mt-1 pl-8 p-2 border-transparent bg-[#ECECEC] placeholder:text-[#212121] w-full rounded-md"
                                                    />
                                                    <SlLocationPin className='absolute mt-0.5 bg-clip-text top-[50%] left-2 translate-y-[-50%]  text-[#FB8500]' />

                                                </div>
                                            </div>
                                            <div>
                                                <label className="block text-sm font-semibold text-[#212121]">To</label>
                                                <div className='relative flex items-center '>
                                                    <input
                                                        type="text"
                                                        placeholder="Location"
                                                        className="mt-1 pl-8 p-2 border-transparent bg-[#ECECEC] placeholder:text-[#212121] w-full rounded-md"
                                                    />
                                                    <SlLocationPin className='absolute mt-0.5 bg-clip-text top-[50%] left-2 translate-y-[-50%]  text-[#FB8500]' />
                                                </div>
                                            </div>
                                            <div className=''>
                                                <button
                                                    type="submit"
                                                    className="w-full bg-gradiant-yellow5 font-semibold text-[17px] text-[#212121] py-2 px-4 rounded-md focus:outline-none"
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>}
                            <div className='max-w-7xl mx-auto my-5'>
                                <DatePicker date={selectedDate} onDateChange={handleDateChange} />
                                <div className='my-4'>
                                    <TripDetails />
                                </div>
                            </div>
                        </div>
                    }
                    {activeTab === 1 &&
                        <div>
                            <section className='max-w-7xl mx-auto lg:my-10 p-0 lg:px-5'>
                                <div className='grid grid-cols-6 lg:grid-cols-12 gap-5 lg:gap-10'>
                                    <div className='col-span-6 lg:col-span-6'>
                                        <img src={Bookingleftside} alt='About_banner' className='w-full h-full max-w-full mx-auto' />
                                    </div>
                                    <div className='col-span-6 lg:col-span-6'>
                                        <div className='lg:my-6'>
                                            <div className='lg:pr-10 w-full h-full space-y-3 lg:space-y-5'>
                                                <h3 className='font-semibold text-base  lg:text-lg'>
                                                    Golden Eagle provides taxis to and from the following major airports, straight to your destination:
                                                </h3>
                                                <ul className='pl-4 lg:pl-8 list-disc text-xs lg:text-base text-start'>
                                                    <li>John F. Kennedy International Airport (JFK)</li>
                                                    <li>LaGuardia Airport (LGA)</li>
                                                    <li>Newark Liberty International Airport (EWR)</li>
                                                    <li>Westchester County Airport (HPN)</li>
                                                </ul>
                                                <p className='text-xs lg:text-base font-normal'>
                                                    We also offer taxi services to Cruise Line Terminals in New York City and New Jersey, along with other destinations upon request. Rates are determined by travel time, passenger count, and destination. Contact us for taxi inquiries HERE.
                                                </p>
                                                <button type='button' onClick={togglePopup} className='bg-gradiant-yellow5 rounded-md px-5 lg:px-10 py-1.5 lg:py-2.5 text-xs lg:text-base font-semibold'>Inquiry Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    }
                    {activeTab === 2 &&
                        <div>
                            <section className='max-w-7xl mx-auto my-5 lg:my-10 p-0 lg:px-5'>
                                <div className='text-center relative mb-5 lg:mb-10'>
                                    <h2 className='lg:w-[60%] mx-auto text-base sm:text-xl lg:text-[32px] lg:leading-tight text-[#212121] font-semibold'>We partner with some of the best companies so you can experience the best of New Jersey & New York City </h2>
                                </div>
                                <div className='flex justify-center items-center  lg:gap-10'>
                                    <div className=''>
                                        <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto object-contain' />
                                    </div>
                                    <div className=''>
                                        <img src={Our_Partner} alt='Our_Partner' className='w-full mx-auto object-contain' />
                                    </div>
                                </div>
                            </section>
                        </div>
                    }
                    {activeTab === 3 && <div>
                        <section className='max-w-7xl mx-auto my-5 lg:my-10 p-0 lg:px-5'>
                            <div className='text-center text-sm lg:text-base space-y-2 lg:space-y-4 lg:mb-10 text-[#212121]'>
                                <p className='mx-auto text-sm lg:text-base leading-snug font-normal'>Golden Eagle provides Private Tours and Third-Party Tours for hotel guests at discounted prices.<br />
                                    If you’d like more information on a private tour,
                                </p>
                                <p>
                                    PLEASE CONTACT US <span className='underline'>HERE.</span>
                                </p>
                                <p>
                                    We work with the following New York City tour companies for hotel guests interested in tours of the Big Apple:
                                </p>
                                <p className='underline'>
                                    - Big Bus NYC
                                </p>
                            </div>

                        </section>
                    </div>
                    }
                    <InquiryDetailsPopup isOpen={isPopupOpen} onClose={togglePopup} />
                </div>
            </div>
        </>
    );
};
const Booking = () => {
    let dispatch = useDispatch()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const productId = queryParams.get('id');
    const { faqdatalist } = useSelector((state) => state.common)

    useEffect(() => {
        dispatch(fetchFaqData())
        dispatch(fetchProducts())

    }, [])


    return (
        <>
            <HeroSection />
            <section className='py-5 lg:py-10'>
                <div className='max-w-7xl mx-auto'>
                    <div className='text-center relative'>
                        <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold mb-2 lg:mb-0'>Experience Seamless Travel Service</h2>
                        <p className='lg:w-[42%] mx-auto lg:pb-3'>Discover the Best: Golden Eagle Shuttle Service. 20 years of excellence connecting North Bergen hotels to NYC. Your effortless gateway to the Big Apple awaits!</p>
                    </div>
                </div>
                <div className=''>
                    <ServiceTabs productId={productId} />

                </div>
            </section>

            <section className='max-w-7xl mx-auto my-5 lg:my-10'>
                {/* <div className='text-center relative mb-10'>
                    <h2 className='text-[32px] text-[#212121] font-semibold'>Who we are </h2>
                    <p className=' w-[40%] text-base mx-auto'>Golden Eagle runs daily door to door shuttles</p>
                </div> */}
                <div className='grid grid-cols-6 lg:grid-cols-12 gap-5 lg:gap-10'>
                    <div className='col-span-6 lg:col-span-6'>
                        <img src={Bookingleftside} alt='About_banner' className='w-full max-w-full mx-auto' />
                    </div>
                    <div className='col-span-6 lg:col-span-6'>
                        <div className='lg:pr-20 w-full h-full flex flex-col justify-center items-center gap-2 lg:gap-4'>
                            <h3 className='font-semibold text-base lg:text-lg'>
                                Golden Eagle's NYC Shuttle: Seamless Travel from North Bergen & Jersey City
                            </h3>
                            <p className='font-normal text-sm lg:text-base'>
                                Golden Eagle runs daily door to door shuttles to New York City for hotel guests staying in North Bergen, NJ and Jersey City, NJ. The shuttle has scheduled runs and tickets must be purchased at least an hour in advance.                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <FaqSection data={faqdatalist} />

        </>
    )
}

export default Booking