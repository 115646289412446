import React from 'react'
import HeroSection from '../home/HeroSection'
import { About_banner, Aboutleftside } from '../../helper/imageConstant'

const AboutUs = () => {
    return (
        <>
            <HeroSection />
            <section className='max-w-7xl mx-auto my-5 lg:my-20 mb-0 pb-0 lg:py-10'>
                <div className='text-center relative mb-8 lg:mb-16'>
                    <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold mb-2'>About Us</h2>
                    <p className='lg:w-[40%] text-base mx-auto'>Discover the Best: Golden Eagle Shuttle Service. 20 years of excellence connecting North Bergen hotels to NYC. Your effortless gateway to the Big Apple awaits!</p>
                </div>
                <div className='space-y-4 lg:space-y-8 mb-10 lg:mb-16'>
                    <p>
                        At Golden Eagle Shuttle Service, we are committed to
                        providing exceptional transportation solutions tailored to your needs and have been for the last
                        20 years. From daily shuttles to New York City, airport transfers, private tours, and discounted
                        group travel, we strive to deliver a reliable and comfortable experience for all our passengers.
                        With a focus on safety, punctuality, and customer satisfaction, our team ensures that every
                        journey is smooth and stress-free.
                    </p>
                    <p>
                        Whether you're commuting, traveling for business, or
                        planning a group adventure, Golden Eagle Shuttle Service is your trusted partner for seamless
                        transportation
                    </p>
                </div>
                <div className=''>
                    <img src={About_banner} alt='About_banner' className='w-full max-w-full mx-auto' />
                </div>
            </section>
            <section className='max-w-7xl mx-auto my-10'>
                <div className='text-center relative mb-10'>
                    <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold mb-2 lg:mb-0'>Who we are </h2>
                    <p className='lg:w-[40%] text-base mx-auto'>Golden Eagle runs daily door to door shuttles</p>
                </div>
                <div className='grid grid-cols-6 lg:grid-cols-12 gap-5 lg:gap-10'>
                    <div className='col-span-6 lg:col-span-6'>
                        <img src={Aboutleftside} alt='About_banner' className='w-full max-w-full mx-auto' />
                    </div>
                    <div className='col-span-6 lg:col-span-6'>
                        <div className='lg:pr-16 w-full h-full flex flex-col justify-center items-center gap-5 lg:gap-10'>
                            <p>
                                For the last 20 years, Golden Eagle Shuttle Service has been serving hotels in the North Bergen, New Jersey area - helping guests travel to New York City and experience everything the Big Apple has to offer. We want to make it easy for you to get to the City.
                            </p>
                            <p className='text-[#FB8500]'>
                                We specialize in daily shuttles to New York City, airport taxis, private tours, and discounted group lavel
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AboutUs