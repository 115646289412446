import React, { useEffect, useState } from 'react'
import { shuttles } from '../../helper/constant';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getCategory } from '../../statemanagement/common/action';
import UserDetailsPopup from '../../component/UserDetailsPopup';
import _ from "lodash";

const Product = () => {
    const { commonLoader, categoryList } = useSelector((state) => state.common)
    const { id } = useParams();
    const navigate = useNavigate();
    let dispatch = useDispatch()

    const [currentPage, setCurrentPage] = useState(1);

    const [isPopupOpen, setPopupOpen] = useState(false);

    const togglePopup = () => {
        setPopupOpen(!isPopupOpen);
    };
    const productData = categoryList.find((shuttle) => shuttle.id === parseInt(id));

    const totalProducts = shuttles.length; // Total count of products

    const itemsPerPage = 5;
    const totalPages = Math.ceil(categoryList.length / itemsPerPage);

    const handlePageClick = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const displayedProducts = categoryList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    useEffect(() => {

        dispatch(getCategory())

    }, [])

    // const [currentPage, setCurrentPage] = useState(1);
    // const itemsPerPage = 5;

    // const totalPages = Math.ceil(shuttles.length / itemsPerPage);
    // const displayedShuttles = shuttles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    // const [currentPage, setCurrentPage] = useState(1);
    // const itemsPerPage = 5;

    // const totalPages = Math.ceil(categoryList.length / itemsPerPage);
    // const displayedShuttles = categoryList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // const handlePageClick = (page) => {
    //     if (page >= 1 && page <= totalPages) {
    //         setCurrentPage(page);
    //     }
    // };
    return (
        <>
            <section className='max-w-7xl mx-auto my-10'>
                <div className="p-4">
                    <h1 className='mb-2 font-semibold text-[19px]'>Product Founds ({productData?.products?.length})</h1>
                    {!_.isEmpty(productData?.products) ?
                        productData?.products?.map((i, index) => (
                            <div key={index} className="w-full border border-[#FFB703] rounded-lg p-4 mb-4 shadow-lg overflow-hidden">
                                <div className="w-full grid grid-cols-12 gap-3">
                                    <div className='col-span-3'>
                                        <img src={i?.img} alt="Shuttle" className=" object-cover rounded-lg" />
                                    </div>
                                    <div className='col-span-6 w-full flex justify-between'>
                                        <div className='w-full'>
                                            <h2 className="w-full text-lg text-[#212121] font-bold">{i?.name}</h2>
                                            <p className="">Duration : {i?.estimated_duration} min</p>
                                            <p className="">Code: {i?.code}</p>
                                            <p className=" w-full font-normal line-clamp-2">{i?.long_desc}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-span-3 w-full flex flex-col justify-between gap-5'>
                                        <div className="text-start flex flex-col items-start ">
                                            <h1 className='text-sm'>Price</h1>
                                            <div className='space-x-4'>
                                                <span className="text-[23px] font-bold">{i?.price}</span>
                                                <span className="text-[#212121] text-[15px] font-semibold line-through">{i?.originalPrice}</span>
                                            </div>
                                        </div>
                                        <div className='inline-flex justify-center'>
                                            <button onClick={() => navigate(`/timetable-booking?id=${i?.id}`)}
                                                type='button' className="w-full inline-block bg-gradiant-yellow-secondary font-semibold px-10 py-2.5 text-[#212121] rounded-lg">Proceed to Book</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))
                        : "Product Not Found"}
                </div>

                {/* {totalPages > 1 && (
                    <Pagination handlePageClick={handlePageClick} currentPage={currentPage} totalPages={totalPages} />
                )} */}
            </section>
            <UserDetailsPopup isOpen={isPopupOpen} onClose={togglePopup} />

        </>
    )
}

export default Product

const Pagination = ({ handlePageClick, currentPage, totalPages }) => {
    return <>
        <div className="flex justify-center mt-4">
            <button
                onClick={() => handlePageClick(1)}
                className={`px-3 py-1 mx-1 ${currentPage === 1 ? 'bg-yellow-500 text-white' : 'bg-gray-200 text-gray-700'} rounded-lg`}
            >
                1
            </button>
            {currentPage > 3 && <span className="px-3 py-1 mx-1">...</span>}
            {currentPage > 2 && (
                <button
                    onClick={() => handlePageClick(currentPage - 1)}
                    className="px-3 py-1 mx-1 bg-gray-200 text-gray-700 rounded-lg"
                >
                    {currentPage - 1}
                </button>
            )}
            {currentPage !== 1 && currentPage !== totalPages && (
                <button
                    onClick={() => handlePageClick(currentPage)}
                    className="px-3 py-1 mx-1 bg-yellow-500 text-white rounded-lg"
                >
                    {currentPage}
                </button>
            )}
            {currentPage < totalPages - 1 && (
                <button
                    onClick={() => handlePageClick(currentPage + 1)}
                    className="px-3 py-1 mx-1 bg-gray-200 text-gray-700 rounded-lg"
                >
                    {currentPage + 1}
                </button>
            )}
            {currentPage < totalPages - 2 && <span className="px-3 py-1 mx-1">...</span>}
            <button
                onClick={() => handlePageClick(totalPages)}
                className={`px-3 py-1 mx-1 ${currentPage === totalPages ? 'bg-yellow-500 text-white' : 'bg-gray-200 text-gray-700'} rounded-lg`}
            >
                {totalPages}
            </button>
        </div>
    </>
}