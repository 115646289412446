import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { GoArrowLeft, GoArrowRight } from 'react-icons/go';

const ShuttleOffersSection = (props) => {
    const { data } = props
    return (
        <section className='max-w-7xl mx-auto my-5 lg:my-10'>
            <div className='text-center relative'>
                <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold lg:title-line pb-2'>Deals</h2>
            </div>
            <div className='max-w-7xl mx-auto py-3 lg:py-5'>
                <div className=''>
                    <Swiper
                        spaceBetween={20}
                        slidesPerView={1}
                        loop={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                                loop: true
                            },
                            // 768: {
                            //     slidesPerView: 4,
                            //     spaceBetween: 40,
                            // },
                            // 1024: {
                            //     slidesPerView: 4,
                            //     spaceBetween: 50,
                            // },
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next-custom',
                            prevEl: '.swiper-button-prev-custom',
                        }}
                        modules={[Navigation]}

                        className="mySwiper !pb-20 lg:!pb-0 relative"

                    >
                        {data?.map((i, j) =>
                            <SwiperSlide key={j} className=''>
                                <div className='w-full relative'>
                                    <img src={i?.image} alt={i?.image} className='w-full rounded-2xl mx-auto' />
                                    <div className='w-2/3 lg:w-3/5 absolute bottom-0 left-0 lg:left-5 p-6 lg:p-8 bg-[#000000] bg-opacity-50 rounded-bl-3xl lg:rounded-t-3xl font-Tsukimi'>
                                        <p className='text-white text-sm lg:text-2xl xl:text-3xl leading-normal text-center'>
                                            {i?.title} </p>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )}
                        <div className='absolute bottom-2 right-0 left-0 flex lg:hidden justify-center items-center gap-2 mt-20'>
                            <div className="swiper-button-prev-custom bg-transparent text-xl text-[#000000] border rounded-full p-3 transition-all ease-in-out duration-75 hover:scale-110"><GoArrowLeft /></div>
                            <div className="swiper-button-next-custom bg-[#FB8500] shadow-md shadow-[#FB8500] text-xl text-[#000000] border border-transparent rounded-full p-3 transition-all ease-in-out duration-75 hover:scale-110"><GoArrowRight /></div>
                        </div>
                    </Swiper>
                </div>
            </div>
        </section>
    )
}

export default ShuttleOffersSection