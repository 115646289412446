import React, { useEffect, useState } from 'react'
import { Menu_icon, Nav_logo } from '../helper/imageConstant'
import { NavLink, useLocation } from 'react-router-dom'
import { MdClose } from 'react-icons/md';

const Header = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { pathname } = useLocation();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const getLinkClass = ({ isActive }) =>
    isActive ? "text-[#FFB703] text-sm font-medium" : "text-[#080909] hover:text-[#FFB703] text-sm font-medium";

  // Scroll to top when pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <header className='!sticky top-0 z-50'>
        <div className='bg-gradiant-yellow p-3 lg:p-2'></div>
        <nav className="bg-white">
          <div className="mx-auto max-w-7xl">
            <div className="px-5 py-2.5 lg:p-2.5">
              <div className="flex items-center justify-between ">
                <NavLink to="/" className="flex flex-shrink-0 items-center">
                  <img className='w-8 lg:hidden' src={Menu_icon} alt='Menu_icon' onClick={toggleMobileMenu} />
                  <img className="w-16" src={Nav_logo} alt="logo" />
                </NavLink>
                <div className="flex items-center gap-4">
                  <div className='hidden lg:block space-x-4'>
                    <NavLink to="/" end className={getLinkClass} aria-current="page">Home</NavLink>
                    <NavLink to="/timetable-booking" className={getLinkClass}>Schedules and Booking</NavLink>
                    <NavLink to="/about-us" className={getLinkClass}>About Us</NavLink>
                    <NavLink to="/contact-us" className={getLinkClass}>Contact Us</NavLink>
                  </div>
                  <div>
                    <button className='header_btn'>Book now</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </nav >
        {/* {isMobileMenuOpen && (
          <div className="bg-white lg:hidden">
            <div className="px-5 py-2.5 space-y-4">
              <Link to="/" className="block text-[#080909] hover:text-[#FFB703] text-sm font-medium" aria-current="page" onClick={toggleMobileMenu}>Home</Link>
              <Link to="/timetable-booking" className="block text-[#080909] hover:text-[#FFB703] text-sm font-medium" onClick={toggleMobileMenu}>Timetable Harmony & Booking Blend</Link>
              <Link to="/about-us" className="block text-[#080909] hover:text-[#FFB703] text-sm font-medium" onClick={toggleMobileMenu}>About Us</Link>
              <Link to="/contact-us" className="block text-[#080909] hover:text-[#FFB703] text-sm font-medium" onClick={toggleMobileMenu}>Contact Us</Link>
              <button className='block w-full text-left header_btn' onClick={toggleMobileMenu}>Book now</button>
            </div>
          </div>
        )} */}
        {/* {
          isMobileMenuOpen &&
          <div className={`fixed inset-y-0 left-0 z-50 w-full bg-white shadow-lg transform transition-transform duration-300 ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}`}>
            <div className="relative px-5 py-2.5 space-y-8">
              <div>
                <div className="flex flex-shrink-0 items-center">
                  <img className="w-32" src={Nav_logo} alt="logo" />
                </div>
                <button className="absolute top-5 right-5 cursor-pointer text-gray-600 hover:text-gray-800" onClick={toggleMobileMenu}>
                  <MdClose size={28} />
                </button>
              </div>
              <div className='flex flex-col gap-5'>

                <NavLink to="/" end className={getLinkClass} aria-current="page" onClick={toggleMobileMenu}>Home</NavLink>
                <NavLink to="/timetable-booking" className={getLinkClass} onClick={toggleMobileMenu}>Timetable Harmony & Booking Blend</NavLink>
                <NavLink to="/about-us" className={getLinkClass} onClick={toggleMobileMenu}>About Us</NavLink>
                <NavLink to="/contact-us" className={getLinkClass} onClick={toggleMobileMenu}>Contact Us</NavLink>

              </div>
              <button className='block  text-left header_btn' onClick={toggleMobileMenu}>Book now</button>
            </div>
          </div>
        } */}
        <div
          className={`fixed inset-0 z-40 bg-black bg-opacity-50 transition-opacity duration-300 ${isMobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
          onClick={toggleMobileMenu}
        ></div>
        <div
          className={`fixed inset-y-0 left-0 z-50 w-full bg-white shadow-lg transform transition-transform duration-300 ${isMobileMenuOpen ? 'translate-x-0' : '-translate-x-full'}`}
        >
          <div className="relative px-5 py-2.5 space-y-8">
            <div>
              <div className="flex flex-shrink-0 items-center">
                <img className="w-32" src={Nav_logo} alt="logo" />
              </div>
              <button className="absolute top-5 right-5 cursor-pointer text-gray-600 hover:text-gray-800" onClick={toggleMobileMenu}>
                <MdClose size={28} />
              </button>
            </div>
            <div className='flex flex-col gap-5'>
              <NavLink to="/" end className={getLinkClass} aria-current="page" onClick={toggleMobileMenu}>Home</NavLink>
              <NavLink to="/timetable-booking" className={getLinkClass} onClick={toggleMobileMenu}>Schedules and Booking</NavLink>
              <NavLink to="/about-us" className={getLinkClass} onClick={toggleMobileMenu}>About Us</NavLink>
              <NavLink to="/contact-us" className={getLinkClass} onClick={toggleMobileMenu}>Contact Us</NavLink>
              <div>
                <button className='block !px-8 py-2  text-left header_btn' onClick={toggleMobileMenu}>Book now</button>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>

  )
}

export default Header