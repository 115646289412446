import './App.css';
import {
  HashRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import 'swiper/swiper-bundle.css'; // Make sure to import Swiper styles
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import Layout from './component/Layout';
import Home from './pages/home/Home';
import AboutUs from './pages/aboutUs/AboutUs';
import ContactUs from './pages/contactUs/ContactUs';
import Booking from './pages/booking/Booking';
import Product from './pages/product/Product';
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<Layout />}>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/about-us" element={<AboutUs />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/timetable-booking" element={<Booking />} />
            <Route exact path="/product" element={<Product />} />
            <Route exact path="/product/:id" element={<Product />} /> {/* Dynamic Route */}

          </Route>

        </Routes>
      </Router>
    </>
  );
}

export default App;
