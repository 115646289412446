import React, { useState } from 'react'
import { BsClock } from 'react-icons/bs';
import { CiSearch } from 'react-icons/ci'
import { MdClose } from 'react-icons/md';
import { SlLocationPin } from 'react-icons/sl';

const Bookingcomponent = ({ trip = true }) => {
  const [isPopupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };
  return (
    <div class="lg:w-[85%] mx-5 lg:mx-auto absolute -bottom-20 lg:-bottom-20 right-0 left-0 lg:flex justify-center ">
      <div class="bg-white p-6 rounded-[14px] lg:rounded-lg shadow-2xl lg:shadow-xl ">
        <div className='w-full flex flex-col lg:flex-row items-center gap-4'>
          {trip && <div class="w-full lg:mb-4">
            <select class="block w-full bg-[#ECECEC] border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
              <option>Select service</option>
            </select>
          </div>}
          {trip && <div class="w-full lg:hidden">
            <select class="block w-full bg-[#ECECEC] border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
              <option>Choose trip</option>
            </select>
          </div>}
          {
            trip &&
            <div class="w-full lg:hidden">
              <button type='button' onClick={togglePopup} class="w-full bg-gradiant-yellow5 font-normal shadow-md shadow-[#FFB703] text-[#212121] text-base p-2 rounded">
                Search Here
              </button>
            </div>
          }
          <div class={`${trip ? "w-full" : "w-2/4"} hidden lg:flex mb-4`}>
            <button class="w-1/2 text-center py-2 rounded-tl-lg rounded-bl-lg bg-gradiant-yellow4">One way trip</button>
            <button class="w-1/2 text-center py-2 rounded-tr-lg rounded-br-lg bg-gray-200">Round trip</button>
          </div>
        </div>
        <div class="hidden lg:flex items-center justify-between gap-4 mb-4">
          {/* <div>
            <label>Depart</label>
            <input type="text" placeholder="Depart" class="block w-full bg-gray-100 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
          </div>
          <div>
            <label>Return</label>
            <input type="text" placeholder="Return" class="block w-full bg-gray-100 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
          </div>
          <div>
            <label>From</label>
            <input type="text" placeholder="From" class="block w-full bg-gray-100 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
          </div>
          <div>
            <label>To</label>
            <input type="text" placeholder="To" class="block w-full bg-gray-100 border border-gray-300 rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" />
          </div> */}
          <div>
            <label className="block text-sm font-semibold text-[#212121]">Depart</label>
            <div className='relative flex items-center '>
              <input
                type="date"
                className="mt-1 p-2 pl-8 border-transparent bg-[#ECECEC] placeholder:text-xs placeholder:text-[#212121] w-full rounded-md"
              />
              <BsClock className='absolute mt-0.5 bg-clip-text top-[50%] left-2 translate-y-[-50%]  text-[#FB8500]' />
            </div>
          </div>
          <div>
            <label className="block text-sm font-semibold text-[#212121]">Return</label>
            <div className='relative flex items-center '>

              <input
                type="date"
                className="mt-1 pl-8 p-2 border-transparent bg-[#ECECEC] placeholder:text-[#212121] w-full rounded-md"
              />
              <BsClock className='absolute mt-0.5 bg-clip-text top-[50%] left-2 translate-y-[-50%]  text-[#FB8500]' />
            </div>
          </div>
          <div>
            <label className="block text-sm font-semibold text-[#212121]">From</label>
            <div className='relative flex items-center '>
              <input
                type="text"
                placeholder="Location"
                className="mt-1 pl-8 p-2 border-transparent bg-[#ECECEC] placeholder:text-[#212121] w-full rounded-md"
              />
              <SlLocationPin className='absolute mt-0.5 bg-clip-text top-[50%] left-2 translate-y-[-50%]  text-[#FB8500]' />

            </div>
          </div>
          <div>
            <label className="block text-sm font-semibold text-[#212121]">To</label>
            <div className='relative flex items-center '>
              <input
                type="text"
                placeholder="Location"
                className="mt-1 pl-8 p-2 border-transparent bg-[#ECECEC] placeholder:text-[#212121] w-full rounded-md"
              />
              <SlLocationPin className='absolute mt-0.5 bg-clip-text top-[50%] left-2 translate-y-[-50%]  text-[#FB8500]' />
            </div>
          </div>
          <div class="text-center mt-4">
            <button class="bg-[#FFB703] shadow-md shadow-[#FFB703] text-black text-base p-2 rounded-full">
              <CiSearch />
            </button>
          </div>
        </div>
      </div>
      <PopupForm isOpen={isPopupOpen} onClose={togglePopup} />
    </div >
  )
}

export default Bookingcomponent

const PopupForm = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <div className="relative w-[320px] sm:w-[480px] lg:w-[640px] overflow-y-scroll bg-popup-bg bg-center bg-no-repeat p-6 rounded-lg shadow-lg">
        <h2 className="text-lg text-[#212121] font-semibold mb-4">Fill up details</h2>
        <div className='absolute top-5 right-5 cursor-pointer'>
          <MdClose size={24} onClick={onClose} />
        </div>
        <form className=''>
          <div className="space-y-4 lg:space-y-4">
            <div>
              <label className="block text-sm font-semibold text-[#212121]">Depart</label>
              <div className='relative flex items-center '>
                <input
                  type="date"
                  className="mt-1 p-2 pl-8 border-transparent bg-[#ECECEC] placeholder:text-xs placeholder:text-[#212121] w-full rounded-md"
                />
                <BsClock className='absolute mt-0.5 bg-clip-text top-[50%] left-2 translate-y-[-50%]  text-[#FB8500]' />
              </div>
            </div>
            <div>
              <label className="block text-sm font-semibold text-[#212121]">Return</label>
              <div className='relative flex items-center '>

                <input
                  type="date"
                  className="mt-1 pl-8 p-2 border-transparent bg-[#ECECEC] placeholder:text-[#212121] w-full rounded-md"
                />
                <BsClock className='absolute mt-0.5 bg-clip-text top-[50%] left-2 translate-y-[-50%]  text-[#FB8500]' />
              </div>
            </div>
            <div>
              <label className="block text-sm font-semibold text-[#212121]">From</label>
              <div className='relative flex items-center '>
                <input
                  type="text"
                  placeholder="Location"
                  className="mt-1 pl-8 p-2 border-transparent bg-[#ECECEC] placeholder:text-[#212121] w-full rounded-md"
                />
                <SlLocationPin className='absolute mt-0.5 bg-clip-text top-[50%] left-2 translate-y-[-50%]  text-[#FB8500]' />

              </div>
            </div>
            <div>
              <label className="block text-sm font-semibold text-[#212121]">To</label>
              <div className='relative flex items-center '>
                <input
                  type="text"
                  placeholder="Location"
                  className="mt-1 pl-8 p-2 border-transparent bg-[#ECECEC] placeholder:text-[#212121] w-full rounded-md"
                />
                <SlLocationPin className='absolute mt-0.5 bg-clip-text top-[50%] left-2 translate-y-[-50%]  text-[#FB8500]' />
              </div>
            </div>
          </div>
          <div className='mt-4'>
            <button
              type="submit"
              className="w-full bg-gradiant-yellow5 font-semibold text-[17px] text-[#212121] py-2 px-4 rounded-md focus:outline-none"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
