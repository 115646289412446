import React, { useEffect, useState } from 'react'
import Bookingcomponent from '../../component/Bookingcomponent'

const HeroSection = ({ booking }) => {
  const [videoData, setVideoData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch video data from the API
    const fetchVideoData = async () => {
      try {
        const response = await fetch('https://goldeneaglesbackend.developersquad.in/api/video');
        const result = await response.json();
        if (result.success) {
          setVideoData(result.data);
        } else {
          setError('Failed to fetch video data');
        }
      } catch (err) {
        setError('Error fetching video data');
      } finally {
        setLoading(false);
      }
    };

    fetchVideoData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const homeVideoUrl = videoData?.Home?.video_url;



  return (
    // <section className='p-0 hero_section relative lg:max-w-7xl mx-auto overflow-visible'>
    //   <div className='hero_bg'>
    //     <div className='hero_text text-center px-4'>
    //       <h2 className=''>
    //         Your reliable ride to NYC, airport transfers, and scenic Hoboken tours—where comfort meets convenience!
    //       </h2>
    //     </div>
    //   </div>
    //   {booking && <Bookingcomponent />}
    // </section>)
    <section className='p-0 hero_section relative lg:max-w-7xl mx-auto overflow-visible'>
      <div className='hero_bg relative'>
        {homeVideoUrl && (
          <video
            autoPlay
            loop
            muted
            playsInline
            className="absolute top-0 left-0 w-full h-full object-cover rounded-[30px]"
          >
            <source src={homeVideoUrl} type="video/mp4" />
          </video>
        )}
        <div className='hero_text text-center px-4 relative z-10'>
          <h2 className='lg:w-4/5 mx-auto'>
            Your reliable ride to NYC, airport transfers, and scenic Hoboken tours—where comfort meets convenience!
          </h2>
        </div>
      </div>
      {booking && <Bookingcomponent />}
    </section>)
}

export default HeroSection
{/* <div className="mx-auto search-container d-flex justify-content-center">
    <form>
      <div className="row mb-3">
        <div className="col">
          <select className="form-select">
            <option>Select service</option>
          </select>
        </div>
        <div className="col">
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="tripType" id="oneWay" defaultChecked />
            <label className="form-check-label" htmlFor="oneWay">One way trip</label>
          </div>
          <div className="form-check form-check-inline">
            <input className="form-check-input" type="radio" name="tripType" id="roundTrip" />
            <label className="form-check-label" htmlFor="roundTrip">Round trip</label>
          </div>
        </div>
      </div>
      <div className="row w-100 d-flex align-items-center justify-content-between">
        <div className="col">
          <label>Depart</label>
          <input type="time" className="form-control" placeholder="Depart" />
        </div>
        <div className="col">
          <label>Return</label>

          <input type="time" className="form-control" placeholder="Return" />
        </div>
        <div className="col">
          <label>From</label>
          <input type="text" className="form-control" placeholder="From" />
        </div>
        <div className="col">
          <label>To</label>
          <input type="text" className="form-control" placeholder="To" />
        </div>
        <div className='col search_btn pt-3'>
          <button type="submit" className="btn btn-warning rounded-circle">
            <CiSearch />
          </button>
        </div>
      </div>
    </form>
  </div> */}