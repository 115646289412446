export const COMMON_LOADER = "COMMON_LOADER";

export const FAQ_LIST = 'FETCH_FAQ';
export const PRODUCT_LIST = 'FETCH_PRODUCT_DATA';
export const CATEGORY_LIST = 'CATEGORY_LIST';
export const PARTNERS_LIST = 'PARTNERS_LIST';
export const TO_LOCATIONS = 'TO_LOCATIONS';
export const FROM_LOCATIONS = 'FROM_LOCATIONS';
export const SAVE_CONTACTDATA = 'SAVE_CONTACTDATA';
export const GENERAL_SETTING_LIST = 'GENERAL_SETTING_LIST';
export const HOTEL_LIST = 'HOTEL_LIST';
export const OFFERS_LIST = 'OFFERS_LIST';
export const SAVE_USER = 'SAVE_USER';


export const HANDLE_USER_DATA = 'HANDLE_USER_DATA';
