import { axiosInstance } from '../../api/apiConfig';
import { FETCH_CATEGORY, FETCH_FAQ, FETCH_HOTEL, FETCH_OFFERS, FETCH_PARTNERS, FETCH_Product, GENERAL_SETTING, SAVE_CONTACTUS, SAVE_USERS } from '../../helper/APIConstant';
import { CATEGORY_LIST, COMMON_LOADER, FAQ_LIST, FROM_LOCATIONS, GENERAL_SETTING_LIST, HANDLE_USER_DATA, HOTEL_LIST, OFFERS_LIST, PARTNERS_LIST, PRODUCT_LIST, TO_LOCATIONS } from './types';

// Action Types
// export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
// export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
// export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';



// Action Creators
// const fetchProductsRequest = () => ({ type: FETCH_PRODUCTS_REQUEST });
// const fetchProductsSuccess = (products) => ({ type: FETCH_PRODUCTS_SUCCESS, payload: products });
// const fetchProductsFailure = (error) => ({ type: FETCH_PRODUCTS_FAILURE, payload: error });



// Thunks
export const fetchProducts = () => {
  return async (dispatch) => {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(FETCH_Product)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: PRODUCT_LIST, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        console.log("err", err);
      });
  };
};
export const fetchFaqData = () => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(FETCH_FAQ)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: FAQ_LIST, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        console.log("err", err);
      });
  };
};
export const getCategory = () => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(FETCH_CATEGORY)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: CATEGORY_LIST, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        console.log("err", err);
      });
  };
};
export const getPartnerlist = () => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(FETCH_PARTNERS)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: PARTNERS_LIST, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        console.log("err", err);
      });
  };
};
export const getToLocation = () => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(FETCH_PARTNERS)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: TO_LOCATIONS, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        console.log("err", err);
      });
  };
};
export const getFromLocation = () => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(FETCH_PARTNERS)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: FROM_LOCATIONS, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        console.log("err", err);
      });
  };
};
export const getGeneralSetting = () => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(GENERAL_SETTING)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: GENERAL_SETTING_LIST, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        console.log("err", err);
      });
  };
};
export const getHotelList = () => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(FETCH_HOTEL)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: HOTEL_LIST, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        console.log("err", err);
      });
  };
};
export const getOffersList = () => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .get(FETCH_OFFERS)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });

        dispatch({ type: OFFERS_LIST, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        console.log("err", err);
      });
  };
};
// export const addProduct = (product) => {
//   return async (dispatch) => {
//     dispatch(addProductRequest());
//     try {
//       const response = await axios.post('/api/products', product);
//       dispatch(addProductSuccess(response.data));
//     } catch (error) {
//       dispatch(addProductFailure(error.message));
//     }
//   };
// };
export const saveContactus = (data) => {
  return async function (dispatch) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    axiosInstance
      .post(SAVE_CONTACTUS, data)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        // console.log('Contact Us form submitted successfully:', res?.data?.data);

        // dispatch({ type: FROM_LOCATIONS, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        console.log("err", err);
      });
  };
};
export const saveUserDetails = () => {
  return async function (dispatch, getState) {
    dispatch({ type: COMMON_LOADER, isLoading: true });
    let data = getState().common?.userData;
    // let obj = {
    //   ...data,

    // };
    axiosInstance
      .post(SAVE_USERS, data)
      .then((res) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        // console.log('Contact Us form submitted successfully:', res?.data?.data);

        // dispatch({ type: FROM_LOCATIONS, response: res?.data?.data });
      })
      .catch((err) => {
        dispatch({ type: COMMON_LOADER, isLoading: false });
        console.log("err", err);
      });
  };
};
// export const saveCountry = (data, onClose) => {
//   return async function (dispatch) {
//     dispatch({ type: ADD_LOADER, isLoading: true });
//     axiosInstanceAuth(2) //Add action type
//       .post(REGIONAL_SETUP.SAVE_COUNTRY, data)
//       .then((res) => {
//         dispatch({ type: ADD_LOADER, isLoading: false });
//         if (res?.data?.responseCode === 0) {
//           dispatch({
//             type: SET_SUCCESS_FOR_MODAL,
//             success: res?.data?.message,
//           });
//           dispatch(fetchCountries());
//           onClose();
//         } else {
//           dispatch({ type: SET_ERROR_FOR_MODAL, error: res?.data?.message });
//         }
//       })
//       .catch((err) => {
//         dispatch({ type: ADD_LOADER, isLoading: false });
//         unAuthorizedUser(err, dispatch);
//       });
//   };
// };



// onChange of salesQuotation
export const handleChangeUserdata = (data) => {
  return async function (dispatch) {
    dispatch({ type: HANDLE_USER_DATA, data: data });
  };
};
