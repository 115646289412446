import React, { useEffect, useState } from 'react'
import { Catchy_line, Contact_img } from '../../helper/imageConstant'
import { useDispatch } from 'react-redux';
import { saveContactus } from '../../statemanagement/common/action';
const initialState = {
    name: "",
    email: "",
    message: "",
};

const ContactSection = () => {
    const dispatch = useDispatch();

    const [saveContactData, setSaveContactData] = useState(initialState);
    const [emailError, setEmailError] = useState("");
    const [successMessage, setSuccessMessage] = useState("");


    const validateEmail = (email) => {
        // Regular expression to validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = (event) => {
        event.preventDefault();  // Prevent page reload

        // Email validation
        if (!saveContactData.email) {
            setEmailError("Email is required.");
        }
        else if (!validateEmail(saveContactData.email)) {
            setEmailError("Please enter a valid email address.");
        } else {
            setEmailError("");  // Clear the error if email is valid
        }
        if (validateEmail(saveContactData.email)) {

            dispatch(saveContactus(saveContactData));
            // Clear the form fields by resetting the state
            setSaveContactData(initialState);
            setSuccessMessage("Your message has been sent successfully!");

        }
    };

    useEffect(() => {
        let emailErrorTimeout;
        let successMessageTimeout;

        if (emailError) {
            emailErrorTimeout = setTimeout(() => {
                setEmailError("");
            }, 3000); // Clear after 3 seconds
        }

        if (successMessage) {
            successMessageTimeout = setTimeout(() => {
                setSuccessMessage("");
            }, 3000); // Clear after 3 seconds
        }

        // Cleanup timeouts on component unmount or when state changes
        return () => {
            clearTimeout(emailErrorTimeout);
            clearTimeout(successMessageTimeout);
        };
    }, [emailError, successMessage]);

    return (
        <>
            <section className='max-w-7xl mx-auto p-0 lg:p-5 my-5 lg:my-10'>
                <div className="w-full h-full">
                    <div className="flex bg-[#212121] text-white lg:rounded-lg shadow-lg overflow-hidden">
                        <div className="hidden md:block w-[40%]">
                            <img src={Contact_img} alt="Contact" className="h-full object-cover" />
                        </div>
                        <div className="px-5 py-10 lg:p-10 lg:w-[60%]">
                            <div className='text-center lg:text-start'>
                                <h2 className="text-3xl font-semibold mb-4">Contact Us</h2>
                                <p className="mb-6 text-sm font-normal">
                                    Lorem ipsum dolor sit amet consectetur. Natoque est pellentesque scelerisque ornare justo suspendisse.
                                </p>
                            </div>
                            <form className="space-y-6">
                                <div className='space-y-4'>
                                    <div>
                                        <label className="block text-sm mb-1 font-semibold" htmlFor="name">Name</label>
                                        <input type="text" name='name' value={saveContactData?.name} className="w-full px-4 py-2 bg-transparent text-white border-gradient border-gradient-yellow rounded-md"
                                            onChange={(e) =>
                                                setSaveContactData({
                                                    ...saveContactData,
                                                    name: e.target.value,
                                                })
                                            }
                                        />
                                    </div>
                                    <div>
                                        <label className="block text-sm mb-1 font-semibold" htmlFor="email">Email</label>
                                        <input type="email" name='email' value={saveContactData?.email} className="w-full px-4 py-2 bg-transparent text-white border-gradient border-gradient-yellow rounded-md"
                                            onChange={(e) =>
                                                setSaveContactData({
                                                    ...saveContactData,
                                                    email: e.target.value,
                                                })
                                            } />
                                        {emailError && (
                                            <p className="text-red-500 text-sm mt-1">{emailError}</p>
                                        )}
                                    </div>
                                    <div>
                                        <label className="block text-sm mb-1 font-semibold" htmlFor="message">Message</label>
                                        <textarea name='message' value={saveContactData?.message} className="w-full px-4 py-2 bg-transparent text-white border-gradient border-gradient-yellow rounded-md" rows="4" onChange={(e) =>
                                            setSaveContactData({
                                                ...saveContactData,
                                                message: e.target.value,
                                            })
                                        }></textarea>
                                    </div>
                                </div>
                                <button onClick={handleSubmit} type="submit" className="w-full mt-2 lg:mt-0 py-2 border-gradient border-gradient-yellow rounded-md text-white font-semibold">
                                    Submit
                                </button>
                            </form>
                            {successMessage && (
                                <p className="text-green-500 text-sm mt-2">{successMessage}</p>
                            )}
                        </div>
                    </div>
                </div>
            </section>
            <div className='mt-10'>
                <div className='max-w-7xl mx-auto mb-10'>
                    <div className='text-center relative'>
                        <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold lg:title-line lg:pb-2'>Add catchy line</h2>
                    </div>
                </div>
                <div className='w-full h-full'>
                    <img src={Catchy_line} alt='Catchy_line' className='object-cover mx-auto w-full' />
                </div>
            </div>
        </>
    )
}

export default ContactSection