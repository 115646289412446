import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { GoArrowLeft, GoArrowRight } from 'react-icons/go';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Our_Partner } from '../../helper/imageConstant';
import { Navigation } from 'swiper/modules'
import 'swiper/swiper-bundle.css'; // Make sure to import Swiper styles

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';


const OurpartnerSection = (props) => {
    const { data } = props
    useEffect(() => {
        // Initialize Swiper with custom navigation buttons after rendering
        const swiper = document?.querySelector('.mySwiper')?.swiper;
        swiper?.navigation?.update();
    }, []);


    return (
        <section className='my-5 lg:my-5'>
            <div className='w-full h-full mx-auto overflow-hidden'>
                <div className='text-center mx-auto'>
                    <h2 className='mb-2 text-2xl lg:text-[32px] text-[#212121] font-semibold '>Our partners</h2>
                    <p className='lg:w-2/4 mx-auto  text-base mb-2 lg:mb-0'>Golden Eagle offers daily door-to-door shuttles to NYC for hotel guests in North Bergen and Jersey City, NJ. Scheduled runs require tickets purchased one hour in advance.</p>
                </div>
                <div className='relative py-5 lg:py-10'>
                    <Swiper
                        slidesPerView={2}
                        spaceBetween={30}
                        breakpoints={{
                            640: {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 20,

                            },
                            992: {
                                slidesPerView: 4,
                                spaceBetween: 20,

                            },
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next-custom',
                            prevEl: '.swiper-button-prev-custom',
                        }}
                        loop={true}
                        modules={[Navigation]}
                        className="mySwiper !pb-2 lg:!pb-10"
                    >
                        {
                            data?.map((i, j) =>
                                <SwiperSlide key={j}>
                                    <div className='p-2'>
                                        <div className='w-full'>
                                            <img src={i?.image} alt='Our_Partner' className='h-[120px] mx-auto grayscale object-contain' />
                                        </div>

                                    </div>
                                </SwiperSlide>
                            )
                        }


                    </Swiper>
                    {/* Custom Navigation Buttons */}
                    <div className='flex justify-center items-center gap-4 mb-5'>
                        <div className="swiper-button-prev-custom bg-transparent text-xl text-[#000000] border rounded-full p-3 transition-all ease-in-out duration-75 hover:scale-110"><GoArrowLeft /></div>
                        <div className="swiper-button-next-custom bg-[#FB8500] shadow-md shadow-[#FB8500] text-xl text-[#000000] border border-transparent rounded-full p-3 transition-all ease-in-out duration-75 hover:scale-110"><GoArrowRight /></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurpartnerSection