export const Nav_logo = "../images/logo.svg"
export const Footer_log = "../images/footer_log.png"
export const Home_bg = "../images/backround/logo.svg"
export const Menu_icon = "../images/icons/hugeicons_menu.svg"

// stock image
export const Hotel_img1 = "../images/stockimage/hotel1.png"
export const Hotel_img2 = "../images/stockimage/hotel2.png"
export const Hotel_img3 = "../images/stockimage/hotel3.png"
export const Hotel_img4 = "../images/stockimage/hotel4.png"


export const Book_img1 = "../images/stockimage/book1.png"
//service image
export const Services1 = "../images/stockimage/services/services1.png"
export const Services2 = "../images/stockimage/services/services2.png"
export const Services3 = "../images/stockimage/services/services3.png"
export const Services4 = "../images/stockimage/services/services4.png"

// client 
export const Client_banner = "../images/stockimage/client_banner.png"
export const Book_banner = "../images/stockimage/book-banner.png"


//avtar 
export const Avtar1 = "../images/avtar/avtar1.png"
export const Avtar2 = "../images/avtar/avtar2.png"
export const Avtar3 = "../images/avtar/avtar3.png"


//our partner
export const Our_Partner = "../images/stockimage/ourPartner.png"
export const Contact_img = "../images/stockimage/contact_img.png"

export const Catchy_line = "../images/stockimage/catchy_line.png"


export const Ticket_icon = "../images/icons/ticket_icon.png"
export const Line_img = "../images/icons/Line.png"
export const Ellipse1_img = "../images/icons/Ellipse1.png"
export const Star_img = "../images/icons/Star.png"
export const Barcode_icon = "../images/stockimage/barcode.png"

//about us page
export const About_banner = "../images/stockimage/about_banner.png"
export const Aboutleftside = "../images/stockimage/aboutleftside.png"


//booking page
export const Bookingleftside = "../images/stockimage/bookingleftside.png"

//tabs image
export const TAB1 = "../images/stockimage/tab1.png"
export const TAB2 = "../images/stockimage/tab2.png"
export const TAB3 = "../images/stockimage/tab3.png"
export const TAB4 = "../images/stockimage/tab4.png"
export const Cloudsandcity = "../images/background/cloudsandcity.svg"

export const Bus_img = "../images/stockimage/bus_img.svg"
export const Product_img = "../images/stockimage/product_img.png"


export const NOT_FOUND_IMG = "../images/stockimage/imagenotfound.png"