import { Product_img } from "./imageConstant";

export const tickets = [
  {
    type: 'Big Bus w/ affiliate link',
    heading: "NYC Sightseeing Pass 48 Hours",
    subheading: "(ROUND TRIP)",
    price: 89,
    save: 10,
    features: ['Hop-On Hop-Off Downtown', 'Hop-On Hop-Off Downtown', 'Hop-On Hop-Off Downtown']
  },
  {
    type: 'American Dream Shuttle', heading: "NYC Sightseeing Pass 48 Hours",
    subheading: "(ROUND TRIP)",

    price: 75,
    save: 10,
    features: ['Hop-On Hop-Off Downtown', 'Hop-On Hop-Off Downtown', 'Hop-On Hop-Off Downtown']
  },
  {
    type: 'Scents and Sips tour', heading: "NYC Sightseeing Pass 48 Hours",
    subheading: "(ROUND TRIP)",
    price: 40,
    save: 10,
    features: ['Hop-On Hop-Off Downtown', 'Hop-On Hop-Off Downtown', 'Hop-On Hop-Off Downtown']
  },
  {
    type: 'Most Popular',
    heading: "NYC Sightseeing Pass 48 Hours",
    subheading: "(ROUND TRIP)",
    price: 89,
    save: 10,
    features: ['Hop-On Hop-Off Downtown', 'Hop-On Hop-Off Downtown', 'Hop-On Hop-Off Downtown']
  },
];

export const faqData = [
  { id: 1, sr: "01", question: "What is your product/service all about?", answer: "Our product/service is designed to..." },
  { id: 2, sr: "02", question: "How does your product/service work?", answer: "Our product/service works by..." },
  { id: 3, sr: "03", question: "Who can benefit from your product/service?", answer: "Anyone who..." },
  { id: 4, sr: "04", question: "Is there a free trial or demo available?", answer: "Yes, we offer..." },
];

export const bookingData = [
  { id: 1, sr: "01", question: "Location", answer: "Our product/service is designed to..." },
  { id: 2, sr: "02", question: "Price", answer: "Our product/service works by..." },
  { id: 3, sr: "03", question: "Schedule Information", answer: "Anyone who..." },
  {
    id: 4, sr: "04", question: "Reservations & Tickets",
    answer: "BOOK YOUR SHUTTLE by purchasing tickets here. Tickets can be purchased up to 2 hours in advance.for reservations, call(201)- 601 - 7777 or e - mail us here."
  },
];

export const shuttles = new Array(20).fill({
  title: 'SHUTTLE From BEST WESTERN HOTEL to NYC',
  duration: '30 Minutes (approx.)',
  code: 'RFGH UJX',
  price: '$25.26',
  originalPrice: '$35.26',
  imageUrl: Product_img // Replace with actual image path
});
