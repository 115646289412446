// import {
//   FETCH_PRODUCTS_REQUEST,
//   FETCH_PRODUCTS_SUCCESS,
//   FETCH_PRODUCTS_FAILURE,
// } from '../product/productaction';

import { CATEGORY_LIST, COMMON_LOADER, FAQ_LIST, FROM_LOCATIONS, GENERAL_SETTING_LIST, HANDLE_USER_DATA, HOTEL_LIST, OFFERS_LIST, PARTNERS_LIST, PRODUCT_LIST, TO_LOCATIONS } from "./types";


const initialUserState = {
  first_name: "",
  last_name: "",
  email: "",
  mobile: "",
  age: "",
  gender: ""
}
const initialState = {
  commonLoader: false,
  faqdatalist: [],
  productList: [],
  categoryList: [],
  partnerList: [],
  toLocationList: [],
  fromLocationList: [],
  generalSettingList: [],
  hotelList: [],
  OffersList: [],
  error: '',
  userData: initialUserState,
};


export default function commonReducer(state = initialState, action) {
  switch (action.type) {
    // case FETCH_PRODUCTS_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    // case FETCH_PRODUCTS_SUCCESS:
    //   return {
    //     loading: false,
    //     products: action.payload,
    //     error: ''
    //   };
    // case FETCH_PRODUCTS_FAILURE:
    //   return {
    //     loading: false,
    //     products: [],
    //     error: action.payload
    //   };
    case COMMON_LOADER:
      return {
        ...state,
        commonLoader: action.isLoading,
      };
    case FAQ_LIST:
      return {
        ...state,
        faqdatalist: action?.response
      };
    case PRODUCT_LIST:
      return {
        ...state,
        productList: action?.response
      };
    case CATEGORY_LIST:
      return {
        ...state,
        categoryList: action?.response
      };
    case PARTNERS_LIST:
      return {
        ...state,
        partnerList: action?.response
      };
    case TO_LOCATIONS:
      return {
        ...state,
        toLocationList: action?.response
      };
    case FROM_LOCATIONS:
      return {
        ...state,
        fromLocationList: action?.response
      };
    case GENERAL_SETTING_LIST:
      return {
        ...state,
        generalSettingList: action?.response
      };
    case HOTEL_LIST:
      return {
        ...state,
        hotelList: action?.response
      };
    case OFFERS_LIST:
      return {
        ...state,
        OffersList: action?.response
      };

    case HANDLE_USER_DATA:
      return {
        ...state,
        userData: action?.data,
      };


    default:
      return state;
  }
};
