// src/reducers/index.js
import { combineReducers } from 'redux';
import commonReducer from './common/reducers';

const rootReducer = combineReducers({
  common: commonReducer,
  // Add other reducers here as your application grows
});

export default rootReducer;
