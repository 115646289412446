import React from 'react'
import { Footer_log } from '../helper/imageConstant'

const Footer = () => {
  return (
    <>
      <footer className='bg-[#212121] py-8 overflow-hidden'>
        <div className='max-w-7xl mx-auto p-4'>
          <div className='grid grid-cols-6 lg:grid-cols-12 gap-10'>
            <div className="col-span-6 lg:col-span-4 flex flex-col lg:items-center gap-5 lg:gap-10">
              <div>
                <img src={Footer_log} alt="Golden Eagle Express" className="w-48 lg:w-full lg:mx-auto" />
                {/* <p className="text-white">Golden Eagle Express</p> */}
              </div>
              <div className="text-white space-y-2">
                <ul className='space-y-4 lg:space-y-5 text-sm font-normal text-white'>
                  <li>
                    <div className='font-semibold text-base text-transparent bg-clip-text  bg-gradient-to-r from-[#FB8500]  to-[#FDD835]'>Call Us: </div>
                    (480) 555-0103
                  </li>
                  <li><div className='font-semibold text-base text-transparent bg-clip-text  bg-gradient-to-r from-[#FB8500]  to-[#FDD835]'>Email:</div> willie.jennings@example.com</li>
                  <li><div className='font-semibold text-base text-transparent bg-clip-text  bg-gradient-to-r from-[#FB8500]  to-[#FDD835]'>Phone no:</div> (239) 555-0108</li>
                </ul>
              </div>
            </div>
            <div className='col-span-6 lg:col-span-8'>
              <div className='grid grid-cols-6 lg:grid-cols-12 gap-5 gap-y-10 gap-x-2 lg:gap-5'>
                <div className="col-span-3 lg:col-span-3 font-semibold text-transparent bg-clip-text  bg-gradient-to-r from-[#FB8500]  to-[#FDD835] space-y-2">
                  <h3 className="font-semibold text-base mb-3 lg:mb-6">Navigation</h3>
                  <ul className='text-white font-normal text-sm space-y-1 lg:space-y-5'>
                    <li>Home</li>
                    <li>Services</li>
                    <li>Schedule</li>
                    <li>Booking</li>
                    <li>About Us</li>
                  </ul>
                </div>
                <div className="col-span-3 lg:col-span-3 font-semibold text-transparent bg-clip-text  bg-gradient-to-r from-[#FB8500]  to-[#FDD835] space-y-2">
                  <h3 className="font-semibold text-base mb-3 lg:mb-6">Policy</h3>
                  <ul className='text-white font-normal text-sm space-y-1 lg:space-y-5'>
                    <li>Privacy policy</li>
                    <li>Terms & Conditions</li>
                    <li>Help</li>
                  </ul>
                </div>
                {/* <div className="col-span-3 lg:col-span-3 font-semibold text-transparent bg-clip-text  bg-gradient-to-r from-[#FB8500]  to-[#FDD835] space-y-2">
                  <h3 className="font-semibold text-base mb-3 lg:mb-6">Policy</h3>
                  <ul className='text-white font-normal text-sm space-y-1 lg:space-y-5'>

                    <li>Privacy policy</li>
                    <li>Terms & Conditions</li>
                    <li>Help</li>
                  </ul>
                </div>
                <div className="col-span-3 lg:col-span-3 font-semibold text-transparent bg-clip-text  bg-gradient-to-r from-[#FB8500]  to-[#FDD835] space-y-2">
                  <h3 className="font-semibold text-base mb-3 lg:mb-6">Policy</h3>
                  <ul className='text-white font-normal text-sm space-y-1 lg:space-y-5'>
                    <li>Privacy policy</li>
                    <li>Terms & Conditions</li>
                    <li>Help</li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className='p-2 bg-gradient-to-r from-[#FB8500]  to-[#FDD835]'>
        <p className='text-xs sm:text-sm lg:text-base text-center text-[#212121] font-semibold'>© 2024 Golden Eagle Shuttles. All rights reserved</p>
      </div>
    </>
  )
}

export default Footer