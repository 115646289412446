import React, { useState } from 'react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

const FaqSection = ({ data }) => {
    const [openId, setOpenId] = useState(null);

    const handleClick = (id) => {
        setOpenId(openId === id ? null : id);
    };
    return (
        <>
            <section className="max-w-7xl mx-auto my-5 lg:my-10">
                <h2 className="text-xl lg:text-3xl font-bold mb-5 lg:mb-10">Frequently asked questions !!</h2>
                {data ? data && data?.map((faq, j) => (
                    <FAQItem
                        key={faq?.id}
                        index={j}
                        faq={faq}
                        isOpen={openId === faq?.id}
                        onClick={handleClick}
                    />
                )) : <div className='flex justify-center items-center'>Data not found</div>}
            </section>
        </>
    )
}

export default FaqSection


const FAQItem = ({ faq, index, isOpen, onClick }) => (
    <div className="bg-collapse-bg bg-no-repeat border rounded-xl my-2">
        <div
            className="flex items-center p-2 lg:p-4 gap-3 cursor-pointer"
            onClick={() => onClick(faq?.id)}
        >
            <span className='bg-gradiant-yellow2 text-sm lg:w-9 lg:h-9 text-center font-semibold p-2.5 rounded-full'>0{index + 1}</span>

            <div className='w-full flex justify-between items-center'>
                <span className='text-[15px] lg:text-base'>{faq?.question}</span>
                <span>
                    {isOpen ?
                        <div className='bg-gradiant-yellow3 text-[#000000] p-1 text-xl rounded-full'>
                            <FaCaretUp />
                        </div>
                        :
                        <div className='bg-gradiant-yellow3 text-[#000000] p-1 text-xl rounded-full'>
                            <FaCaretDown />
                        </div>
                    }
                </span>
            </div>
        </div>
        <div
            className={`overflow-hidden transition-all duration-300 ease-in-out transform ${isOpen ? 'scale-y-100 opacity-100 max-h-96' : 'scale-y-0 opacity-0 max-h-0'}`}
        >
            <div className="p-2 lg:p-4 text-[15px] lg:text-base">
                {faq.answer}
            </div>
        </div>
    </div>
);