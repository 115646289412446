import React from 'react'
import { CiSearch } from 'react-icons/ci'
import { Avtar1, Avtar2, Avtar3, Client_banner } from '../../helper/imageConstant'

const ClientSection = () => {
    return (
        <section className='max-w-7xl mx-auto my-5 lg:my-10'>
            <div className='text-center relative'>
                <h2 className='text-2xl lg:text-[32px] text-[#212121] font-semibold mb-2'>Real Customers, Real Reviews</h2>
                <p className=' lg:w-[45%] mx-auto lg:title-line lg:pb-3'>See What Our Customers Have to Say!
                </p>
            </div>
            <div className='max-w-7xl mx-auto py-5 lg:py-10'>
                <div className='grid grid-cols-6 lg:grid-cols-12 gap-5'>
                    <div className='col-span-6 lg:col-span-6 flex flex-col items-center gap-5 lg:gap-10 justify-center order-2 lg:order-1'>
                        <div className=' w-full p-4 shadow-lg rounded-[20px]'>
                            <div className='flex items-start gap-4'>
                                <span className='bg-gradiant-yellow-secondary rounded-full p-1.5 lg:p-2.5'>
                                    <CiSearch className='text-xl lg:text-2xl' />
                                </span>
                                <div className='space-y-2'>
                                    <h2 className='text-[14px] lg:text-[17px] font-semibold leading-normal'>Exploring to forge unforgettable memories.</h2>
                                    <p className='text-[13px] lg:text-[15px] leading-normal'>Lorem ipsum dolor sit amet consectetur. Id tellus ac aliquet varius. Risus congue morbi elementu  Lorem ipsum dolor sit amet consectetu congue morbi elementu  Lorem ipsum dolor sit amet consectetu</p>
                                </div>
                            </div>
                        </div>
                        <div className=' w-full p-4 shadow-lg rounded-[20px]'>
                            <div className='flex items-start gap-4'>
                                <span className='bg-gradiant-yellow-secondary rounded-full p-1.5 lg:p-2.5'>
                                    <CiSearch className='text-xl lg:text-2xl' />
                                </span>
                                <div className='space-y-2'>
                                    <h2 className='text-[14px] lg:text-[17px] font-semibold leading-normal'>Exploring to forge unforgettable memories.</h2>
                                    <p className='text-[13px] lg:text-[15px] leading-normal'>Lorem ipsum dolor sit amet consectetur. Id tellus ac aliquet varius. Risus congue morbi elementu  Lorem ipsum dolor sit amet consectetu congue morbi elementu  Lorem ipsum dolor sit amet consectetu</p>
                                </div>
                            </div>
                        </div>
                        <div className=' w-full p-4 shadow-lg rounded-[20px]'>
                            <div className='flex items-start gap-4'>
                                <span className='bg-gradiant-yellow-secondary rounded-full p-1.5 lg:p-2.5'>
                                    <CiSearch className='text-xl lg:text-2xl' />
                                </span>
                                <div className='space-y-2'>
                                    <h2 className='text-[14px] lg:text-[17px] font-semibold leading-normal'>Exploring to forge unforgettable memories.</h2>
                                    <p className='text-[13px] lg:text-[15px] leading-normal'>Lorem ipsum dolor sit amet consectetur. Id tellus ac aliquet varius. Risus congue morbi elementu  Lorem ipsum dolor sit amet consectetu congue morbi elementu  Lorem ipsum dolor sit amet consectetu</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-span-6 lg:col-span-6 order-1 lg:order-2'>
                        <div className='w-full relative py-5'>
                            <img src={Client_banner} alt='hotel' className='w-full mx-auto lg:p-10' />
                            <div className='absolute -top-3 lg:top-5 left-32 lg:right-32'>
                                <div className='flex items-center gap-2 bg-white border p-2 pr-6 shadow-md rounded-2xl'>
                                    <img src={Avtar1} alt='Avtar1' className='' />
                                    <div className=''>
                                        <h3 className='text-sm lg:text-base font-semibold'>Arjun Pathak</h3>
                                        <div className='flex gap-2 text-sm'>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6328 1.12969L12.7359 5.92656C12.815 6.10709 12.9412 6.26307 13.1011 6.37823C13.2611 6.49339 13.449 6.5635 13.6453 6.58125L18.7687 7.04063C19.3484 7.125 19.5797 7.83594 19.1594 8.24531L15.3 11.4875C14.9875 11.75 14.8453 12.1625 14.9312 12.5609L16.0531 17.8125C16.1516 18.3891 15.5469 18.8297 15.0281 18.5562L10.5562 15.9375C10.3876 15.8385 10.1956 15.7863 9.99999 15.7863C9.80443 15.7863 9.6124 15.8385 9.44374 15.9375L4.97187 18.5547C4.45468 18.8266 3.84843 18.3875 3.94687 17.8109L5.06874 12.5594C5.15312 12.1609 5.01249 11.7484 4.69999 11.4859L0.839054 8.24687C0.420304 7.83906 0.651554 7.12656 1.22968 7.04219L6.35312 6.58281C6.54942 6.56507 6.73735 6.49495 6.89731 6.3798C7.05727 6.26464 7.18338 6.10865 7.26249 5.92812L9.36562 1.13125C9.62655 0.60625 10.3734 0.60625 10.6328 1.12969Z" fill="#FDD835" />
                                                <path d="M10.4797 6.21408L10.1234 2.6797C10.1094 2.48282 10.0687 2.14532 10.3844 2.14532C10.6344 2.14532 10.7703 2.66564 10.7703 2.66564L11.8391 5.50314C12.2422 6.58282 12.0766 6.95314 11.6875 7.17189C11.2406 7.42189 10.5812 7.22657 10.4797 6.21408Z" fill="#FFFF8D" />
                                                <path d="M14.8875 11.1734L17.9532 8.78124C18.1047 8.65467 18.3782 8.45311 18.1594 8.22342C17.986 8.04217 17.5172 8.30311 17.5172 8.30311L14.8344 9.35155C14.0344 9.62811 13.5032 10.0375 13.4563 10.5531C13.3954 11.2406 14.0125 11.7703 14.8875 11.1734Z" fill="#F4B400" />
                                            </svg>
                                            3.9 / 5
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='absolute bottom-[50%] -left-3 lg:left-5'>
                                <div className='flex items-center gap-2 bg-white border p-2 pr-6 shadow-md rounded-2xl'>
                                    <img src={Avtar2} alt='Avtar1' className='' />
                                    <div className=''>
                                        <h3 className='text-sm lg:text-base font-semibold'>Guy Hawkins</h3>
                                        <div className='flex gap-2 text-sm'>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6328 1.12969L12.7359 5.92656C12.815 6.10709 12.9412 6.26307 13.1011 6.37823C13.2611 6.49339 13.449 6.5635 13.6453 6.58125L18.7687 7.04063C19.3484 7.125 19.5797 7.83594 19.1594 8.24531L15.3 11.4875C14.9875 11.75 14.8453 12.1625 14.9312 12.5609L16.0531 17.8125C16.1516 18.3891 15.5469 18.8297 15.0281 18.5562L10.5562 15.9375C10.3876 15.8385 10.1956 15.7863 9.99999 15.7863C9.80443 15.7863 9.6124 15.8385 9.44374 15.9375L4.97187 18.5547C4.45468 18.8266 3.84843 18.3875 3.94687 17.8109L5.06874 12.5594C5.15312 12.1609 5.01249 11.7484 4.69999 11.4859L0.839054 8.24687C0.420304 7.83906 0.651554 7.12656 1.22968 7.04219L6.35312 6.58281C6.54942 6.56507 6.73735 6.49495 6.89731 6.3798C7.05727 6.26464 7.18338 6.10865 7.26249 5.92812L9.36562 1.13125C9.62655 0.60625 10.3734 0.60625 10.6328 1.12969Z" fill="#FDD835" />
                                                <path d="M10.4797 6.21408L10.1234 2.6797C10.1094 2.48282 10.0687 2.14532 10.3844 2.14532C10.6344 2.14532 10.7703 2.66564 10.7703 2.66564L11.8391 5.50314C12.2422 6.58282 12.0766 6.95314 11.6875 7.17189C11.2406 7.42189 10.5812 7.22657 10.4797 6.21408Z" fill="#FFFF8D" />
                                                <path d="M14.8875 11.1734L17.9532 8.78124C18.1047 8.65467 18.3782 8.45311 18.1594 8.22342C17.986 8.04217 17.5172 8.30311 17.5172 8.30311L14.8344 9.35155C14.0344 9.62811 13.5032 10.0375 13.4563 10.5531C13.3954 11.2406 14.0125 11.7703 14.8875 11.1734Z" fill="#F4B400" />
                                            </svg>
                                            3.9 / 5
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='absolute -bottom-3 lg:bottom-5 right-10 lg:right-20'>
                                <div className='flex items-center gap-2 bg-white border p-2 pr-6 shadow-md rounded-2xl'>
                                    <img src={Avtar3} alt='Avtar1' className='' />
                                    <div className=''>
                                        <h3 className='text-sm lg:text-base font-semibold'>Annette Black</h3>
                                        <div className='flex gap-2 text-sm'>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M10.6328 1.12969L12.7359 5.92656C12.815 6.10709 12.9412 6.26307 13.1011 6.37823C13.2611 6.49339 13.449 6.5635 13.6453 6.58125L18.7687 7.04063C19.3484 7.125 19.5797 7.83594 19.1594 8.24531L15.3 11.4875C14.9875 11.75 14.8453 12.1625 14.9312 12.5609L16.0531 17.8125C16.1516 18.3891 15.5469 18.8297 15.0281 18.5562L10.5562 15.9375C10.3876 15.8385 10.1956 15.7863 9.99999 15.7863C9.80443 15.7863 9.6124 15.8385 9.44374 15.9375L4.97187 18.5547C4.45468 18.8266 3.84843 18.3875 3.94687 17.8109L5.06874 12.5594C5.15312 12.1609 5.01249 11.7484 4.69999 11.4859L0.839054 8.24687C0.420304 7.83906 0.651554 7.12656 1.22968 7.04219L6.35312 6.58281C6.54942 6.56507 6.73735 6.49495 6.89731 6.3798C7.05727 6.26464 7.18338 6.10865 7.26249 5.92812L9.36562 1.13125C9.62655 0.60625 10.3734 0.60625 10.6328 1.12969Z" fill="#FDD835" />
                                                <path d="M10.4797 6.21408L10.1234 2.6797C10.1094 2.48282 10.0687 2.14532 10.3844 2.14532C10.6344 2.14532 10.7703 2.66564 10.7703 2.66564L11.8391 5.50314C12.2422 6.58282 12.0766 6.95314 11.6875 7.17189C11.2406 7.42189 10.5812 7.22657 10.4797 6.21408Z" fill="#FFFF8D" />
                                                <path d="M14.8875 11.1734L17.9532 8.78124C18.1047 8.65467 18.3782 8.45311 18.1594 8.22342C17.986 8.04217 17.5172 8.30311 17.5172 8.30311L14.8344 9.35155C14.0344 9.62811 13.5032 10.0375 13.4563 10.5531C13.3954 11.2406 14.0125 11.7703 14.8875 11.1734Z" fill="#F4B400" />
                                            </svg>
                                            3.9 / 5
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>)
}

export default ClientSection