import React, { useEffect } from 'react'
import { Book_banner } from '../../helper/imageConstant'
import HeroSection from './HeroSection';
import HotelsSection from './HotelsSection';
import ShuttleOffersSection from './ShuttleOffersSection';
import ServicesSection from './ServicesSection';
import ClientSection from './ClientSection';
import OurpartnerSection from './OurpartnerSection';
import CtaSection from './CtaSection';
import FaqSection from './FaqSection';
import ContactSection from './ContactSection';
import PackageSection from './PackageSection';
import { fetchFaqData, fetchProducts, getCategory, getGeneralSetting, getHotelList, getOffersList, getPartnerlist } from '../../statemanagement/common/action';
import { useDispatch, useSelector } from 'react-redux';

const Home = () => {
  let dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFaqData())
    dispatch(fetchProducts())
    dispatch(getCategory())
    dispatch(getPartnerlist())
    dispatch(getGeneralSetting())
    dispatch(getHotelList())
    dispatch(getOffersList())
  }, [])

  const { commonLoader, faqdatalist, productList, categoryList, partnerList, generalSettingList, hotelList, OffersList } = useSelector((state) => state.common)
  // console.log('loading', commonLoader, faqdatalist)


  return (
    <>
      <HeroSection booking />
      <HotelsSection data={hotelList} />
      <ShuttleOffersSection data={OffersList} />
      <ServicesSection data={categoryList} />
      <ClientSection />
      <PackageSection />
      {/* <DemoSlide /> */}
      <section className='p-0 my-5 lg:my-10'>
        <div className='relative w-full h-full'>
          <img src={Book_banner} alt='' className='w-full h-[332px] lg:h-full mx-auto object-cover' />
          <div className='absolute bg-[#000000] bg-cover bg-center bg-opacity-60 top-0 left-0 right-0 bottom-0 flex justify-center items-center h-full'>
            <div className='text-center'>
              <h2 className='mx-auto lg:w-full text-2xl lg:text-[32px] text-[#fff] font-semibold mb-5'>Ready to take on NYC's adventures?</h2>
              <p className='lg:w-[55%] mb-2 lg:mb-0 px-2  mx-auto font-normal text-sm lg:text-xl leading-normal text-[#fff] pb-4 lg:pb-3'>Golden Eagle offers daily door-to-door shuttles to NYC for hotel guests in North Bergen and Jersey City, NJ. Scheduled runs require tickets purchased one hour in advance.</p>
              <button className="text-[#212121] font-semibold rounded-full uppercase text-base leading-normal bg-gradient-to-r from-[#FFB703] to-[#FD9D01] px-8 py-3">book now</button>
            </div>
          </div>
        </div>
      </section>
      <OurpartnerSection data={partnerList} />
      <CtaSection data={generalSettingList} />
      <FaqSection
        data={faqdatalist}
      />
      <ContactSection />
    </>
  )
}

export default Home