import React from 'react'
import { useState } from 'react';
import { MdClose } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { handleChangeUserdata, saveUserDetails } from '../statemanagement/common/action';


// const PassengerDetailsPopup = ({ isUserpopup, onClose }) => {
//     const [form, setForm] = useState({
//         name: '',
//         gender: '',
//         age: '',
//         contact: '',
//         email: '',
//     });

//     const handleChange = (e) => {
//         setForm({ ...form, [e.target.name]: e.target.value });
//     };

//     const handleSubmit = (e) => {
//         e.preventDefault();
//         // Add your form submission logic here
//         console.log(form);
//     };

//     if (!isUserpopup) return null;

//     return <>
//         <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
//             <div className="w-[720px] bg-popup-bg bg-center bg-no-repeat p-6 rounded-lg shadow-lg">
//                 <h2 className="text-lg font-bold mb-4">Details</h2>
//                 <form onSubmit={handleSubmit}>
//                     <div className="mb-4">
//                         <label className="block text-sm font-semibold text-[#212121]">Date & time</label>
//                         <input
//                             type="text"
//                             name="datetime"
//                             value={form.name}
//                             onChange={handleChange}
//                             className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
//                             placeholder="Enter time date"
//                             required
//                         />
//                     </div>
//                     <div className="mb-4">
//                         <label className="block text-sm font-semibold text-[#212121]">Name</label>
//                         <input
//                             type="text"
//                             name="name"
//                             value={form.name}
//                             onChange={handleChange}
//                             className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
//                             placeholder="Enter name"
//                             required
//                         />
//                     </div>

//                     <div className="mb-4">
//                         <label className="block text-sm font-semibold text-[#212121]">Email</label>
//                         <input
//                             type="email"
//                             name="email"
//                             value={form.age}
//                             onChange={handleChange}
//                             className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
//                             placeholder="Enter email"
//                             required
//                         />
//                     </div>
//                     <div className="mb-4">
//                         <label className="block text-sm font-semibold text-[#212121]">Mobile no.</label>
//                         <input
//                             type="tel"
//                             name="contact"
//                             value={form.contact}
//                             onChange={handleChange}
//                             className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
//                             placeholder="Enter number"
//                             required
//                         />
//                     </div>
//                     <div className="mb-4">
//                         <label className="block text-sm font-semibold text-[#212121]">Hotel Name</label>
//                         <input
//                             type="text"
//                             name="hname"
//                             value={form.email}
//                             onChange={handleChange}
//                             className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
//                             placeholder="Enter hotel name"
//                             required
//                         />
//                     </div>
//                     <div className="mb-5">
//                         <label className="block text-sm font-semibold text-[#212121]">Room no.</label>
//                         <input
//                             type="text"
//                             name="roomNo"
//                             value={form.email}
//                             onChange={handleChange}
//                             className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
//                             placeholder="Enter room no."
//                             required
//                         />
//                     </div>

//                     <div className="flex justify-end items-center">
//                         <button
//                             type="submit"
//                             className="bg-gradiant-yellow2 text-[#212121]  font-semibold capitalize rounded-md py-2 px-6"
//                         >
//                             Proceed to Pay
//                         </button>
//                     </div>
//                 </form>
//             </div>
//         </div>
//     </>
// }
const PassengerDetailsPopup = ({ isUserpopup, onClose }) => {
    const [form, setForm] = useState({
        name: '',
        gender: '',
        age: '',
        contact: '',
        email: '',
    });

    const handleChange = (e) => {
        setForm({ ...form, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your form submission logic here
    };

    if (!isUserpopup) return null;

    return <>
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="relative w-[360px] bg-popup-bg bg-center bg-no-repeat p-6 rounded-lg shadow-lg">
                <h2 className="text-lg font-bold mb-4">Traveller Details</h2>
                <div className='absolute top-5 right-5 cursor-pointer'>
                    <MdClose size={24} onClick={onClose} />
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm font-semibold text-[#212121]">Adults</label>
                        <select className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md">
                            <option defaultValue={"choose size"}>Choose Size</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-semibold text-[#212121]">Child</label>
                        <select className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md">
                            <option defaultValue={"choose size"}>Choose Size</option>
                        </select>
                    </div>
                    <div className="flex justify-end items-center">
                        <button
                            onClick={onClose}
                            type="submit"
                            className="w-full bg-gradiant-yellow4 text-[#212121] font-semibold uppercase rounded-md py-2 px-6"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </>
}

const UserDetailsPopup = ({ isOpen, onClose }) => {
    const dispatch = useDispatch();

    const [isUserpopup, setIsUserpopup] = useState(false);
    const [errors, setErrors] = useState({});

    const { userData } = useSelector((state) => state.common)

    const isOpenPopup = () => {
        setIsUserpopup(!isUserpopup);
    };

    // Combine first and last name for display
    const handleNameChange = (e) => {
        const fullName = e.target.value.trim();
        const nameParts = fullName.split(" ");
        const firstName = nameParts[0] || "";
        const lastName = nameParts.slice(1).join(" ") || ""; // Handle multiple last name parts
        dispatch(
            handleChangeUserdata({
                ...userData,
                first_name: firstName,
                last_name: lastName,
            })
        );
    };

    // Handle gender change
    const handleGenderChange = (e) => {
        dispatch(
            handleChangeUserdata({
                ...userData,
                gender: e.target.value,
            })
        );
    };
    const validateForm = () => {
        let formErrors = {};

        if (!userData.first_name || !userData.last_name) {
            formErrors.name = "Full name is required";
        }

        if (!userData.age) {
            formErrors.age = "Age is required";
        }

        if (!userData.mobile || !/^\d{10}$/.test(userData.mobile)) {
            formErrors.mobile = "Valid contact number is required";
        }

        if (!userData.email || !/\S+@\S+\.\S+/.test(userData.email)) {
            formErrors.email = "Valid email is required";
        }

        if (!userData.gender) {
            formErrors.gender = "Gender is required";
        }

        setErrors(formErrors);

        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate all fields
        if (validateForm()) {
            dispatch(saveUserDetails());
        }
    };

    if (!isOpen) return null;

    return (
        <>
            <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
                <div className="relative w-[720px] bg-popup-bg bg-center bg-no-repeat p-6 rounded-lg shadow-lg">
                    <h2 className="text-lg font-bold mb-4">Details</h2>
                    <div className='absolute top-5 right-5 cursor-pointer'>
                        <MdClose size={24} onClick={onClose} />
                    </div>
                    <form>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">Passenger Name</label>
                            <input
                                type="text"
                                name="first_name"
                                value={`${userData.first_name} ${userData.last_name}`}
                                onChange={handleNameChange}

                                className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
                                placeholder="Enter name"
                                required
                            />
                            {errors.name && <p className="text-red-500 text-sm">{errors.name}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">Gender</label>
                            <div className="mt-1 flex items-center space-x-4">
                                <label>
                                    <input
                                        type="radio"
                                        name="gender"
                                        value="Male"
                                        checked={userData.gender === "Male"}
                                        onChange={handleGenderChange}
                                        className="mr-2"
                                        required
                                    />
                                    Male
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        name="gender"
                                        checked={userData.gender === "Female"}
                                        onChange={handleGenderChange}
                                        className="mr-2"
                                        required
                                    />
                                    Female
                                </label>
                                {errors.gender && <p className="text-red-500 text-sm">{errors.gender}</p>}
                            </div>
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">Age</label>
                            <input
                                type="number"
                                name="age"
                                value={userData.age}
                                onChange={(e) => dispatch(
                                    handleChangeUserdata({
                                        ...userData,
                                        age: e.target.value
                                    })
                                )} className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
                                placeholder="Enter age"
                                required
                            />
                            {errors.age && <p className="text-red-500 text-sm">{errors.age}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">Contact no.</label>
                            <input
                                type="tel"
                                name="mobile"
                                value={userData.mobile}
                                maxLength={10}
                                onChange={(e) => dispatch(
                                    handleChangeUserdata({
                                        ...userData,
                                        mobile: e.target.value
                                    })
                                )} className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
                                placeholder="Enter number"
                                required
                            />
                            {errors.mobile && <p className="text-red-500 text-sm">{errors.mobile}</p>}
                        </div>
                        <div className="mb-4">
                            <label className="block text-sm font-semibold text-[#212121]">Email</label>
                            <input
                                type="email"
                                name="email"
                                value={userData.email}
                                onChange={(e) => dispatch(
                                    handleChangeUserdata({
                                        ...userData,
                                        email: e.target.value
                                    })
                                )} className="mt-1 p-2 border-transparent bg-[#ECECEC] w-full rounded-md"
                                placeholder="Enter email"
                                required
                            />
                            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                        </div>
                        <div className="mb-4 text-end">
                            <button
                                type="button"
                                onClick={isOpenPopup}
                                className="text-[#212121] font-semibold"
                            >
                                + Add passenger details
                            </button>
                        </div>
                        <div className="mb-4">
                            <label className="inline-flex items-start text-[#212121] font-normal">
                                <input type="checkbox" className="mr-2 mt-1" />
                                Sent request on your email
                            </label>
                        </div>
                        <div className="mb-4">
                            <label className="inline-flex items-start text-[#212121] font-normal">
                                <input type="checkbox" className="mr-2 mt-1" required />
                                By clicking on proceed, I agree that I have read and understood <br />the TnCs and the Privacy Policy
                            </label>
                        </div>
                        <div className="flex justify-between items-center">
                            <div className="">
                                <p className="text-lg font-bold">Total Amount : $50.99</p>
                            </div>
                            <button
                                onClick={handleSubmit}
                                type="submit"
                                className="bg-gradiant-yellow2 text-[#212121] font-semibold capitalize rounded-md py-2 px-6"
                            >
                                Proceed to Pay
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <PassengerDetailsPopup isUserpopup={isUserpopup} onClose={isOpenPopup} />
        </>
    )
}
export default UserDetailsPopup
